import { FC, useEffect, useState } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { getListOpenOrders } from '@/api/limit-order'
import { TOpenLimitOrder } from '@/libs/types/limit-order.type'
import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

const OrdersTable: FC = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const [rows, setRows] = useState<TOpenLimitOrder[]>([])
  // const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)
  // const [keySort, setKeySorting] = useState('d')

  const fetchOpenOrders = async () => {
    const { data } = await getListOpenOrders(currentToken?.token?.address ?? '')
    return data.data.triggers
  }
  useEffect(() => {
    const orders = fetchOpenOrders()
    orders.then((data) => {
      if (data) {
        const mutableUserHistory = [...data]
        setRows(
          mutableUserHistory.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          ),
        )
      }
    })
  }, [currentToken])

  // const handleSort = (key: string) => {
  //   setKeySorting(key)
  //   setSorting((sorting) => {
  //     return sorting === Sorting.ASC && key === keySort ? Sorting.DESC : Sorting.ASC
  //   })
  // }

  // const sortedRows = useMemo(() => {
  //   return rows
  //     .filter((row: any) => +row.ba !== 0)
  //     .sort((a: any, b: any) => {
  //       const aValue = a[keySort]?.u ?? a[keySort]
  //       const bValue = b[keySort]?.u ?? b[keySort]

  //       const dateA = new Date(aValue)
  //       const dateB = new Date(bValue)

  //       if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
  //         return sorting === Sorting.ASC
  //           ? dateA.getTime() - dateB.getTime()
  //           : dateB.getTime() - dateA.getTime()
  //       }

  //       if (typeof aValue === 'string' && typeof bValue === 'string') {
  //         return sorting === Sorting.ASC
  //           ? aValue.localeCompare(bValue)
  //           : bValue.localeCompare(aValue)
  //       }

  //       const numA = parseFloat(aValue)
  //       const numB = parseFloat(bValue)

  //       if (!isNaN(numA) && !isNaN(numB)) {
  //         return sorting === Sorting.ASC ? numA - numB : numB - numA
  //       }

  //       return 0
  //     })
  // }, [rows, sorting, keySort])

  return (
    <Paper className={styles.root}>
      <TableContainer className={cn(styles.container)} id="holdings-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={cn(styles.tableHeadCell, styles.tokenCell)}>Token</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.amountCell)}>Amount</TableCell>
              <TableCell className={styles.tableHeadCell}>Condition</TableCell>
              <TableCell className={styles.tableHeadCell}>Trigger Price</TableCell>
              <TableCell className={styles.tableHeadCell}>Current Price</TableCell>
              {/* <TableCell className={styles.tableHeadCell}>Triggered At</TableCell> */}
              <TableCell className={styles.tableHeadCell}>Status</TableCell>
              <TableCell className={styles.tableHeadCell}>Slippage</TableCell>
              {/* <TableCell className={styles.tableHeadCell}>Priority Fee</TableCell> */}
              <TableCell className={styles.tableHeadCell}>Created/expiry</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 &&
              rows.map((row: TOpenLimitOrder) => (
                <Row row={row} key={row.id} refetch={fetchOpenOrders} setRows={setRows} />
              ))}
            {/* {!sortedRows && isLoading && <RowLoadingPlaceholder styleValiant="lite" />} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { OrdersTable }
