import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { cancelOrder } from '@/api/limit-order'
import { CustomToast } from '@/components/custom-toast/custom-toast'
import styles from '@/components/pending-orders/libs/components/limit-order-table/styles.module.scss'
import rowStyles from '@/components/pending-orders/row-styles.module.scss'
import { ButtonIcon, Chip, Icon, Typography } from '@/libs/common'
import { IconWithTooltip } from '@/libs/common/icon-with-tooltip'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { TOpenLimitOrder } from '@/libs/types/limit-order.type'

type TProps = {
  row: TOpenLimitOrder
  index: number
  refetch: () => void
  setRows: React.Dispatch<React.SetStateAction<TOpenLimitOrder[]>>
}

const Row: FC<TProps> = ({ row, index, refetch, setRows }) => {
  const [open, setOpen] = useState(false)
  // const navigate = useCustomNavigate()
  const { t } = useTranslation()

  // const handleEdit = () => {
  //   const currentPath =
  //     row.trigger_type === 'Limit Buy' ? AppRoute.EDIT_LIMIT_BUY : AppRoute.EDIT_LIMIT_SELL

  //   navigate({
  //     isDashboard: true,
  //     path: `${AppRoute.MODAL}/${currentPath}/${row.trigger.buy_template.token}`,
  //     state: {
  //       name: row.token_name,
  //     },
  //   })
  // }

  const handleCancelOrder = async () => {
    try {
      await cancelOrder(row.id)
      CustomToast('success', t('translation.info.order_cancelled'))
      refetch()
      setRows((prev: TOpenLimitOrder[]) =>
        prev.filter((item: TOpenLimitOrder) => item.id !== row.id),
      )
    } catch (error) {
      CustomToast('error', (error as any).message)
    }
  }

  const expirationTime = moment.unix(row.trigger.trigger.execution.expiration)

  return (
    <>
      <TableRow
        className={cn(rowStyles.tableRow, {
          [rowStyles.backgroundEven]: index % 2 !== 0,
          [rowStyles.backgroundOdd]: index % 2 === 0,
        })}
      >
        <TableCell className={cn(rowStyles.cell, rowStyles.rowCell)}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon className={rowStyles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={rowStyles.icon} />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.paddingZero, styles.wallet)}
          component="th"
          scope="row"
          align="center"
        >
          {row.trigger.buy_template.wallets.length}
        </TableCell>

        <TableCell
          className={cn(
            rowStyles.cell,
            rowStyles.tableRow,
            rowStyles.paddingZero,
            styles.type,
            styles.visible,
          )}
          component="th"
          scope="row"
          align="center"
        >
          {row.trigger_type === 'Limit Sell' && (
            <IconWithTooltip
              iconName={IconName.SELL2}
              messageClassName={rowStyles.tooltipWrapper}
              tooltip={
                <div className={rowStyles.tooltip}>
                  <Icon name={IconName.SELL2} />
                  <Typography textColor="light-grey">{t('sell')}</Typography>
                </div>
              }
            />
          )}
          {row.trigger_type === 'Limit Buy' && (
            <IconWithTooltip
              messageClassName={rowStyles.tooltipWrapper}
              iconName={IconName.SHOPPING_CART}
              tooltip={
                <div className={rowStyles.tooltip}>
                  <Icon name={IconName.SHOPPING_CART} />
                  <Typography textColor="light-grey">{t('buy')}</Typography>
                </div>
              }
            />
          )}
        </TableCell>

        <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.token)}>
          {row.token_name}
        </TableCell>

        <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.amount)}>
          {formatNumber(row.trigger.buy_template.operation.setup.buy_amount).formatted}
        </TableCell>

        <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.limit)}>
          {formatNumber(row.trigger.trigger.fixed.price_usd).formatted}
        </TableCell>

        <TableCell
          className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.rowPadding, styles.exp)}
          align="center"
        >
          {row.trigger.trigger.execution.expiration !== 0 ? (
            <Box display="flex" flexDirection="column">
              <div> {expirationTime.format('DD.MM')}</div>
              <div>{expirationTime.format('HH:mm')}</div>
            </Box>
          ) : (
            <Typography textColor="light-grey">-</Typography>
          )}
        </TableCell>

        <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.status)}>
          <Chip key={row.id} styleVariant="grey2" label={row.status} />
        </TableCell>

        <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.tableButtonsRow)}>
          {/* <ButtonIcon tooltipTitle="Edit" onClick={handleEdit}>
            <Icon name={IconName.EDIT} />
          </ButtonIcon> */}

          <ButtonIcon
            tooltipTitle={t('delete')}
            onClick={handleCancelOrder}
            className={rowStyles.deleteCell}
          >
            <Icon name={IconName.DELETE} />
          </ButtonIcon>
        </TableCell>
      </TableRow>

      <TableRow className={rowStyles.backgroundEven}>
        <TableCell
          className={cn(rowStyles.cell, rowStyles.cellWrapper)}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} width={'100%'}>
              <Table classes={rowStyles} size="small" aria-label="purchases">
                <TableHead className={rowStyles.tableHead}></TableHead>

                <TableBody>
                  {row.trigger.buy_template.wallets.map((wallet, index) => (
                    <TableRow key={wallet.name + index}>
                      <TableCell
                        className={cn(rowStyles.cell, styles.innerWalletName)}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {wallet.name}
                      </TableCell>

                      {/* <TableCell className={cn(rowStyles.cell, rowStyles.spentCeil)}>
                        {wallet.name}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { Row }
