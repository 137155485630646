import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TOrderResponse } from '@/libs/types'
import { TLimitBuyTransaction } from '@/libs/types/buy-transaction'
import { TOpenLimitOrderResponse } from '@/libs/types/limit-order.type'
import { store } from '@/store'

const BASE_URL = 'api/v1/triggers'

const createLimitOrder = (payload: TLimitBuyTransaction) => {
  const userId = getCurrentUserId()
  return api.post(
    `${BASE_URL}/base/${userId}/limit?l=en&b=${payload.buy_template.network.blockchain}`,
    payload,
  )
}

const cancelOrder = (triggerId: string) => {
  const blockchain = store.getState().chain.currentChain.id

  api.delete(`${BASE_URL}/base/${getCurrentUserId()}/limit/${triggerId}?l=en&b=${blockchain}`)
}

const getOrdersHistory = (token: string) => {
  const blockchain = store.getState().chain.currentChain.id
  //   {{endpoint}}/api/{{version}}/triggers/base/{{user}}/limit/token/{{token}}/history?l={{language}}&b={{blockchain}}
  return api.get<TOrderResponse>(
    `api/v1/triggers/base/${getCurrentUserId()}/limit/token/${token}/history?l=en&b=${blockchain}`,
  )
}

const getListOpenOrders = (token: string) => {
  const blockchain = store.getState().chain.currentChain.id
  //   {{endpoint}}/api/{{version}}/triggers/base/{{user}}/limit/token/{{token}}/history?l={{language}}&b={{blockchain}}
  return api.get<TOpenLimitOrderResponse>(
    `api/v1/triggers/base/${getCurrentUserId()}/limit/token/${token}/open?l=en&b=${blockchain}`,
  )
}

export { cancelOrder, createLimitOrder, getListOpenOrders, getOrdersHistory }
