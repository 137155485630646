import { FC, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormControlLabel, Grid, RadioGroup } from '@mui/material'
import cn from 'classnames'
import * as yup from 'yup'

import { createLimitOrder } from '@/api/limit-order'
import { makeManualBuy } from '@/api/orders'
import { BuyAmountGroup } from '@/components/buy-amount-group'
import { CustomToast } from '@/components/custom-toast'
import { MAX_AMOUNT_LENGTH } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/constants'
import styles from '@/components/dashboard-component-lite/libs/buy-sell-block/styles.module.scss'
import { MarketCapDropdownBuy } from '@/components/market-cap-dropdown/buy'
import { PriorityCurrency } from '@/components/priority-currency'
import useCheckUser from '@/hooks/useCheckUser'
import { Accordion, Button, Icon, Input, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { Radio } from '@/libs/common/radio'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName, TransactionResponseType } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { createLimitBuyPayload } from '@/libs/helper/buy/createLimitBuyPayload'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { handleError } from '@/libs/helper/handleError'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { TBuyTransaction, TLimitBuyTransaction } from '@/libs/types/buy-transaction'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchPriorities } from '@/store/slices/user.slice'

import { calculatePriceFromMC } from '../../helpers/calculateData'
import { getTriggerMessage } from '../../helpers/helpers'

type TProperty = {
  onExpand?: (value: boolean) => void
}

const BuyTab: FC<TProperty> = ({ onExpand }) => {
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const userData = useAppSelector((state) => state.user.userData)
  const quickBuySettings = useAppSelector((state) => state.user.quickBuySettings)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const checkUserAndExecute = useCheckUser()
  const dispatch = useAppDispatch()

  const formRef = useRef<HTMLFormElement>(null)

  const defaultValues = {
    amount: quickBuySettings.isInitialized ? quickBuySettings.settings.spend : '',
    privateTransaction: !currentChain.features?.noPrivateTx,
    slippage: currentChain.defaultValues.slippage,
    buyPriority: defaultUserPriorities?.buy_priority ?? '',
    antiMev: false,
    bribeAmount: defaultUserPriorities?.bribe_amount ?? '',
    transactionType: 'market',
    marketCapType: 'mc-is',
    marketCap: '',
    // expiration: '',
  }

  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test((value) => +value <= +(mainWallet?.balanceFormatted || 0)),
    transactionType: yup.string().oneOf(['market', 'limit']).required(),
    marketCapType: yup.string().when('transactionType', {
      is: 'limit',
      then: (schema) =>
        schema.oneOf(['mc-by', 'mc-is']).required('Market cap type is required for limit orders'),
      otherwise: (schema) => schema.optional(),
    }),
    marketCap: yup.string().when('transactionType', {
      is: 'limit',
      then: (schema) =>
        schema.required('Market cap is required for limit orders').test({
          name: 'mc-check',
          message:
            'MC entered is higher than current MC. Clicking buy will trigger instant market buy',
          test: function (value) {
            if (!value || !simulation?.market_cap) return true

            return Number(value) <= Number(simulation.market_cap)
          },
        }),
      otherwise: (schema) => schema.optional(),
    }),
    // expiration: yup.string().when('transactionType', {
    //   is: 'limit',
    //   then: (schema) => schema.required('Expiration is required for limit orders'),
    //   otherwise: (schema) => schema.optional(),
    // }),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  useEffect(() => {
    if (!userData) return
    const setPriorities = async () => {
      const data = await dispatch(fetchPriorities(null)).unwrap()
      setValue('buyPriority', data.buy_priority)
      setValue('bribeAmount', data.bribe_amount)
    }
    setPriorities()

    setValue('privateTransaction', !currentChain.features?.noPrivateTx)
    setValue('slippage', currentChain.defaultValues.slippage)
  }, [currentChain.id])

  const marketCapValue = watch('marketCap')
  const marketCapType = watch('marketCapType')
  useEffect(() => {
    if (marketCapType === 'mc-is' && marketCapValue && simulation?.market_cap) {
      if (Number(marketCapValue) > Number(simulation?.market_cap)) {
        setError('marketCap', {
          type: 'manual',
          message:
            'MC entered is higher than current MC. Clicking buy will trigger instant market buy',
        })
      } else {
        clearErrors('marketCap')
      }
    }
  }, [marketCapValue, marketCapType, simulation?.market_cap])

  const txTypeState = watch('transactionType')

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!mainWallet) return

    try {
      setIsLoading(true)

      let receive
      let coeff
      const price = calculatePriceFromMC(
        +marketCapValue,
        simulation?.total_supply ? +simulation.total_supply : 0,
      )

      if (data.transactionType === 'limit') {
        coeff = currentChain.nativeTokenPriceInUsd ? currentChain.nativeTokenPriceInUsd / price : 0
        receive = convertScientificNotationNumber(+data.amount * coeff, MAX_TRX_DECIMALS)
      } else {
        coeff =
          currentChain.nativeTokenPriceInUsd && currentToken?.token.price
            ? currentChain.nativeTokenPriceInUsd / +currentToken?.token.price
            : 0
        receive = convertScientificNotationNumber(+data.amount * coeff, MAX_TRX_DECIMALS)
      }

      const payloadData = {
        data: {
          advancedBuy: {
            approvePriority: '',
            buyPriority: data.buyPriority,
            maxTxOrFail: false,
            minPercentTokenOrFail: '',
          },
          shields: {
            buy_tax: '',
            maximum_liquidity: '',
            maximum_market_cap: '',
            minimum_liquidity: '',
            sell_tax: '',
          },
          ordinaryBuy: {
            degenChadMode: false,
            privateTransaction: data.privateTransaction,
            receive: receive,
            selectedWallets: [mainWallet.address],
            slippage: data.slippage,
            spend: data.amount,
          },
        },
        wallets: [
          {
            name: mainWallet.name,
            public_key: mainWallet.address,
          },
        ],
      }

      if (!payloadData) {
        CustomToast('error', 'Something went wrong')
        return
      }

      let payload
      let response

      if (data.transactionType === 'limit') {
        payload = createLimitBuyPayload({
          ...payloadData,
          pair: currentToken?.pair?.address ?? '',
          price_usd: price,
        }) as TLimitBuyTransaction
        response = await createLimitOrder(payload)
      } else {
        payload = createManualBuyPayload(payloadData) as TBuyTransaction
        response = await makeManualBuy(payload)
      }

      if (!payload) {
        CustomToast('error', 'Something went wrong')
        return
      }

      processTransactionResponse(response.data, TransactionResponseType.BUY)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderAdvancedSettings = () => (
    <Accordion
      className={styles.accordion}
      title={t('settings.advanced_settings')}
      withDivider
      mainColorArrow
      onChange={onExpand}
    >
      <Grid container rowGap={2}>
        {!currentChain.features?.noPrivateTx && (
          <Controller
            name="privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label={t('template.anti_mev')}
                tooltipInfo={t('template.anti_mev_tooltip')}
                {...field}
              />
            )}
          />
        )}
        <Controller
          name="slippage"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PercentsInput
              label={t('template.slippage')}
              onOptionSelect={(value) => field.onChange(value)}
              tooltipInfo={t('template.slippage_tooltip')}
              placeholder="X"
              error={!!errors.slippage?.message}
              {...field}
            />
          )}
        />
        {!!currentChain.features?.buyPriority && (
          <Controller
            name="buyPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                {...field}
                className={styles.input}
                label={getChainSensitiveLabel('buyPriority')}
                placeholder={t('template.enter_gwei_amount', {
                  currency: currentChain.priorityCurrency.toLowerCase(),
                })}
                isNumeric
                tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                endAdornment={<PriorityCurrency />}
                error={!!errors.buyPriority?.message}
                onChange={(e: any) => {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )

                  field.onChange(newValue)
                }}
              />
            )}
          />
        )}
        {!!currentChain.features?.bribeAmount && (
          <Controller
            name="bribeAmount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                {...field}
                className={styles.input}
                label={getChainSensitiveLabel('bribeAmount')}
                placeholder={`Enter ${currentChain.priorityCurrency.toLowerCase()} amount`}
                isNumeric
                tooltipInfo="An extra fee that you can add to increase the likelihood of your transaction being included in a block."
                endAdornment={<PriorityCurrency />}
                error={!!errors.buyPriority?.message}
                onChange={(e: any) => {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  field.onChange(newValue)
                }}
              />
            )}
          />
        )}
      </Grid>
    </Accordion>
  )

  const renderAmountControls = () => (
    <>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <BuyAmountGroup
            solid
            exclusive
            className={styles.radioGroup}
            groupClassName={styles.radioGroupContent}
            value={value}
            onChange={(_, value) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              const newValue = convertScientificNotationNumber(value, MAX_TRX_DECIMALS, true)
              onChange(newValue)
            }}
            {...field}
          />
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <Input
            label={t('token_info.amount')}
            placeholder={t('amount_values.amount_to_buy')}
            isNumeric
            className={styles.input}
            maxLength={MAX_AMOUNT_LENGTH}
            isHideInputCaption
            endAdornment={
              <Grid display="flex" alignItems="center" columnGap={2}>
                <Typography fontWeight={400}>
                  ($
                  {
                    formatNumber(+field.value * (currentChain.nativeTokenPriceInUsd || 0), 2)
                      .formatted
                  }
                  )
                </Typography>
                <EndAdornment
                  label={currentChain.chainSymbol}
                  icon={(IconName as any)[currentChain.iconName]}
                />
              </Grid>
            }
            onChange={(e) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              if (!e.target.value) {
                onChange(e.target.value)
                return
              }
              const newValue = convertScientificNotationNumber(
                e.target.value,
                MAX_TRX_DECIMALS,
                true,
              )
              onChange(newValue)
            }}
            error={!!errors.amount?.message}
            {...field}
          />
        )}
      />
      <div className={styles.available}>
        <Typography variant="body2" className={styles.param}>
          {t('available')}:
        </Typography>
        <Typography variant="body2" className={styles.value}>
          {mainWallet?.balanceFormatted
            ? (+mainWallet.balanceFormatted || 0).toLocaleString('en-US', {
                maximumFractionDigits: 5,
              })
            : 0}{' '}
          {currentChain.chainSymbol}
        </Typography>
      </div>
    </>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Controller
        name="transactionType"
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} className={styles.txType}>
            <FormControlLabel
              value="market"
              control={<Radio label={t('limit_order.buy_now')} />}
              label=""
              className={styles.radioWrapper}
            />
            <FormControlLabel
              value="limit"
              control={<Radio label={t('limit_order.limit_buy')} />}
              label=""
              className={styles.radioWrapper}
            />
          </RadioGroup>
        )}
      />
      {renderAdvancedSettings()}
      {renderAmountControls()}
      {txTypeState === 'limit' && (
        <div className={styles.limitContainer}>
          <Controller
            name="marketCap"
            control={control}
            render={({ field }) => (
              <Input
                label=""
                placeholder={t('limit_order.enter_market_cap')}
                isNumeric
                className={styles.input}
                maxLength={MAX_AMOUNT_LENGTH}
                isHideInputCaption
                startAdornment={
                  <Controller
                    name="marketCapType"
                    control={control}
                    render={({ field: typeField }) => <MarketCapDropdownBuy {...typeField} />}
                  />
                }
                endAdornment={<EndAdornment label={marketCapType === 'mc-by' ? '%' : '$'} />}
                onChange={(e) => {
                  if (!currentToken || !currentChain.nativeTokenPriceInUsd) return

                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  field.onChange(newValue)
                }}
                value={field.value}
                error={!!errors.marketCap?.message}
              />
            )}
          />
          {marketCapValue && (
            <div className={cn(styles.triggerBlock, styles.buyTriggerBlock)}>
              <span className={styles.infoIcon}>
                <TooltipIcon info="" color={errors.marketCap?.message ? '#E96363' : ''} />
              </span>
              <div className={styles.trigger}>
                {!!errors.marketCap?.message === false ? (
                  <>
                    <div className={styles.triggerHeader}>
                      <Typography variant="body2" className={styles.param}>
                        {t('limit_order.triggered_when')}
                      </Typography>
                    </div>
                    <Typography variant="body2" className={styles.value}>
                      {getTriggerMessage(
                        marketCapType,
                        marketCapValue,
                        currentChain,
                        false,
                        +(
                          mainWallet?.balanceFormatted ? +mainWallet.balanceFormatted : 0
                        ).toLocaleString('en-US', {
                          maximumFractionDigits: 5,
                        }),
                      )}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" className={styles.errorMessage} textColor="red">
                    {t('limit_order.market_cap_error')}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <Link
        to={`${AppRoute.DASHBOARD}/${AppMode.LITE}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
        className={styles.link}
      >
        <Typography variant="body2">
          {getChainSensitiveLabel('setupDefaultPriorities')} <Icon name={IconName.SETTING_2} />
        </Typography>
      </Link>
      <div className={styles.divider}>
        <Typography variant="body2" className={styles.text}>
          OR
        </Typography>
      </div>
      <Button
        type="button"
        isLoading={isLoading}
        checkOnAccountLock
        onClick={() => checkUserAndExecute(() => formRef.current?.requestSubmit())}
      >
        {t('buy')}
      </Button>
    </form>
  )
}

export { BuyTab }
