import { FC } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'
import { t } from 'i18next'

import tableStyles from '@/components/pending-orders/table-styles.module.scss'
import { Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TOpenLimitOrder } from '@/libs/types/limit-order.type'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

type TProperty = {
  rows: TOpenLimitOrder[]
  refetch: () => void
  setRows: React.Dispatch<React.SetStateAction<TOpenLimitOrder[]>>
}

const LimitOrdersTable: FC<TProperty> = ({ rows, refetch, setRows }) => {
  return (
    <TableContainer>
      <Table
        classes={tableStyles}
        aria-label="collapsible table"
        size="small"
        className={tableStyles.container}
      >
        <TableHead className={tableStyles.tableHead}>
          <TableRow>
            <TableCell className={cn(tableStyles.cell, tableStyles.arrowCeil)} />

            <TableCell
              className={cn(
                tableStyles.cell,
                tableStyles.headCell,
                tableStyles.icon,
                styles.wallet,
              )}
              align="center"
            >
              <Icon name={IconName.WALLET} key={0} />
            </TableCell>

            <TableCell
              className={cn(tableStyles.cell, tableStyles.headCell, styles.type)}
              align="center"
            >
              {t('token_info.type')}
            </TableCell>

            <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.token)}>
              {t('token_list.token')}
            </TableCell>

            <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.amount)}>
              {t('token_info.amount')}
            </TableCell>

            <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.limit)}>
              {t('orders.limit')}
            </TableCell>

            <TableCell
              className={cn(tableStyles.cell, tableStyles.headCell, styles.exp)}
              align="center"
            >
              EXP
            </TableCell>

            <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.amount)}>
              {t('token_info.status')}
            </TableCell>

            <TableCell className={cn(tableStyles.cell, tableStyles.headCellWidth)}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={tableStyles.tableBody}>
          {rows &&
            rows.map((row, index) => (
              <Row key={row.id} row={row} index={index} refetch={refetch} setRows={setRows} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { LimitOrdersTable }
