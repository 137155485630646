import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Box, Divider } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import { generateLinkedDeviceCode, getDevicesList } from '@/api/device'
import { updateUser } from '@/api/user'
import { ConnectWalletButton } from '@/components/header/libs/components/connect-wallet/connect-wallet-button.tsx'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Button,
  ButtonIcon,
  Icon,
  InputSearch,
  Popover,
  SignOutButton,
  Switch,
  TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { Logo } from '@/libs/common/logo'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
// import { PlanName } from '@/libs/enums'
// import { TPlan } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUser } from '@/store/slices/user.slice.ts'

import { CustomToast } from '../custom-toast/custom-toast'
import { ChainStatusInfo } from './libs/components/chain-status-info'
import { InfoItem } from './libs/components/info-item'
import { SwitchVariant } from './libs/components/switch-variant'
import { UserBlock } from './libs/components/user-block'
import styles from './styles.module.scss'

const Header: FC<{ setCollapsed: Dispatch<SetStateAction<boolean>> }> = ({ setCollapsed }) => {
  const { mode } = useParams()
  const { t } = useTranslation()
  // const isProduction = useMemo(() => process.env.NODE_ENV === 'production', [])

  // const currentLanguage = useMemo(() => i18n.language.split('-')[0], [i18n.language])

  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const [popoverState, setPopoverState] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const [isDevicesExist, setIsDevicesExist] = useState(false)
  const navigate = useCustomNavigate()
  const { width } = useWindowDimensions()
  const isMobileSidebar = width < 768
  const isTablet = width > 480 && width < 1200
  const dispatch = useAppDispatch()

  const checkDevices = async () => {
    const { data } = await getDevicesList()
    setIsDevicesExist(data?.data?.length > 0)
  }

  useEffect(() => {
    localStorage.removeItem('signData')
    localStorage.removeItem('account')

    checkDevices()

    // localStorage.removeItem('signUpData')
  }, [])

  const closeMenu = (): void => {
    setPopoverState({
      visible: false,
      anchorE: undefined,
    })
  }

  const handleLogoClick = () => {
    navigate({
      isDashboard: true,
      searchParams: {
        [TabName.DASHBOARD_NAV]: 'dashboard',
        token: selectedTokenAddress || currentChain.defaultToken,
      },
      replace: true,
    })
  }

  const handleTelegramClick = async () => {
    const randomId = uuidv4()

    const { data } = await generateLinkedDeviceCode({
      id: randomId,
      name: 'telegram',
      type: '1',
    })

    // const botUrl = isProduction ? 'blazing_trading_bot' : 'tesst1_BB_bot'
    const botUrl = 'blazing_trading_bot'
    const link = `https://t.me/${botUrl}?start=device_${data.data.code}`
    if (data?.data?.code) {
      CustomToast('success', t('toast.success.telegram_link_generated'))

      await new Promise((resolve) => setTimeout(resolve, 1500))
      window.open(link, '_blank')
      await checkDevices()
    }
  }

  const setConfirmation = async (state: boolean) => {
    const { data } = await updateUser({ sell_confirmation: state })
    dispatch(setUser(data.data))
  }
  const renderNavigation = () => (
    <nav className={styles.nav}>
      {/* <Link className={styles.navLink} to="/wallets">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
          <Icon name={IconName.WALLET} />
        </div>
        Wallets
      </Link>

      <Link className={styles.navLink} to="/settings">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconSettings)}>
          <Icon name={IconName.SETTING} />
        </div>
        Settings
      </Link> */}

      {!isDevicesExist && (
        <Button onClick={handleTelegramClick} className={styles.telegramButton}>
          <span className={styles.icon}>
            <Icon name={IconName.TELEGRAM} />
          </span>
          <span className={styles.buttonText}>{t('menu.sync-telegram')}</span>
        </Button>
      )}
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.DEPOSIT} />
          <span className={styles.buttonText}>{t('menu.deposit')}</span>
        </div>
      </Link>
      {!!currentChain.features?.transfers && (
        <Link
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${mainWallet?.address}`}
          className={styles.buttonLink}
          onClick={closeMenu}
        >
          <div className={styles.navLink}>
            <Icon name={IconName.TRANSFER} />
            <span className={styles.buttonText}>{t('menu.transfer')}</span>
          </div>
        </Link>
      )}
      {!!currentChain.features?.bridge && (
        <Link
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.BRIDGE}`}
          className={styles.buttonLink}
          onClick={closeMenu}
        >
          <div className={styles.navLink}>
            <Icon name={IconName.BRIDGE} />
            <span className={styles.buttonText}>Bridge</span>
          </div>
        </Link>
      )}
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=affiliates`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.AFFILIATE} />
          <span className={styles.buttonText}>{t('menu.affiliates')}</span>
        </div>
      </Link>
      {/* TODO: Uncomment to enable languages select */}
      {/* <div className={styles.buttonLink}>
        <Grid display="flex" alignItems="center" justifyContent="space-between">
          <div className={cn(styles.navLink, styles.language)}>
            <Icon name={IconName.LANGUAGE_GOLD} />
            <span className={styles.buttonText}>{t('menu.language')}</span>
          </div>
          <Select
            options={languagesConfig}
            value={currentLanguage}
            selectRootProps={{ variant: ESelectRootVariant.BORDERLESS }}
            onChange={(value: string) => {
              changeLanguage(value)
            }}
          />
        </Grid>
      </div> */}
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.GOLD_WALLET} />
          <span className={styles.buttonText}>{t('menu.wallets')}</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=account`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
            <Icon name={IconName.SETTING_2} />
          </div>
          <span className={styles.buttonText}>{t('dashboard.settings')}</span>
        </div>
      </Link>
      <Divider variant="fullWidth" className={styles.divider} />
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          closeMenu()
          navigate({
            isDashboard: true,
            path: `${AppRoute.MODAL}/${AppRoute.ACCOUNT_LOCKING}`,
          })
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.LOCK} />
          </div>
          {userData?.locked ? t('menu.unlock') : t('menu.lock')} {t('menu.account')}
        </div>

        <Switch value={userData?.locked || false} checked={userData?.locked || false} />
      </Grid>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          setConfirmation(!userData?.sell_confirmation)
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.APPROVE} />
          </div>
          {t('menu.sell_confirmation')}
        </div>

        <Switch
          value={userData?.sell_confirmation || false}
          checked={userData?.sell_confirmation || false}
        />
      </Grid>
      <div className={styles.navLink}>
        <SignOutButton variant="onboarding" buttonClassName={styles.signOutButton} />
      </div>
    </nav>
  )

  return (
    <>
      <Grid
        ref={ref}
        container
        component="header"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        className={cn(styles.header, styles[currentChain.chainName])}
      >
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
          className={styles.container}
        >
          <div className={cn(styles.logoWrapper, mode ? styles[mode] : '')}>
            {mode == AppMode.LITE && (
              <Logo
                onClick={handleLogoClick}
                className={styles.logoButton}
                size="medium"
                hideText={isMobileSidebar}
              />
            )}
            {mode == AppMode.PRO && (
              <>
                {width < 1200 && (
                  <Box
                    display="flex"
                    gap={3}
                    alignItems="center"
                    className={styles.logoMenuWrapper}
                  >
                    <ButtonIcon
                      icon={IconName.SIDEBAR_COLLAPSED_BUTTON}
                      className={cn(styles.controlMobile, { [styles.open]: true })}
                      onClick={() => setCollapsed((state) => !state)}
                    />

                    {isTablet && (
                      <Logo
                        onClick={handleLogoClick}
                        className={styles.logoButton}
                        size="medium"
                        hideText={isMobileSidebar}
                      />
                    )}
                  </Box>
                )}

                {!isTablet && (
                  <Logo
                    onClick={handleLogoClick}
                    className={styles.logoButton}
                    size="medium"
                    hideText={isMobileSidebar}
                  />
                )}
              </>
            )}

            <div className={styles.search}>
              <SwitchVariant />

              {width >= 1200 && (
                <>
                  <div className={styles.searchBlock}>
                    <InputSearch placeholder={t('menu.enter_token')} />
                  </div>
                  <div className={styles.infoWrapper}>
                    {userData && (
                      <Link
                        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=affiliates`}
                        className={styles.buttonLink}
                      >
                        <Button
                          className={cn(styles.affiliatesButton)}
                          styleVariant="black"
                          startIcon={<Icon name={IconName.AFFILIATE} />}
                        >
                          {t('menu.affiliates')}
                        </Button>
                      </Link>
                    )}
                    <ChainStatusInfo />
                  </div>
                </>
              )}
            </div>

            {width < 1200 && <ChainStatusInfo />}

            {userData ? (
              <div
                className={styles.userData}
                onClick={(e) => {
                  const newPopoverState = {
                    visible: !popoverState.visible,
                    anchorE: e.currentTarget,
                  }
                  setPopoverState(newPopoverState)
                }}
              >
                <div>
                  <UserBlock userName={userData?.username} />

                  <InfoItem
                    title={`${t('balance')}:`}
                    value={
                      <Typography
                        variant="caption"
                        textColor="light-grey"
                        className={styles.balance}
                        title={mainWallet?.balanceFormatted}
                      >
                        {ChainNameToNativeTokenIcon[currentChain.chainName]}
                        {mainWallet?.balanceFormatted
                          ? formatNumber(mainWallet?.balanceFormatted).formatted
                          : 0}
                      </Typography>
                    }
                  />
                </div>

                <div className={styles.switch}>
                  <Icon name={IconName.SWITCH} />
                </div>
              </div>
            ) : (
              <>
                {/* TODO: Uncomment to enable languages select */}
                {/* <Grid display="flex" alignItems="center" justifyContent="space-between">
                  <Select
                    options={languagesConfig}
                    value={currentLanguage}
                    onChange={(value: string) => {
                      changeLanguage(value)
                    }}
                    selectRootProps={{
                      prefix: (
                        <div className={styles.noUserLanguageIcon}>
                          <Icon name={IconName.LANGUAGE_GOLD} />
                        </div>
                      ),
                      formatValueDisplay: (label) =>
                        LANGUAGE_TO_ISO_LABEL[label as keyof typeof LANGUAGE_TO_ISO_LABEL],
                    }}
                  />
                </Grid> */}
                <ConnectWalletButton />
              </>
            )}

            {!isMobileSidebar && (
              <Popover
                className={styles.popover}
                popoverState={popoverState}
                onChangeVisible={setPopoverState}
              >
                {renderNavigation()}
              </Popover>
            )}
          </div>
        </Grid>

        {isMobileSidebar && (
          <div
            className={cn(styles.mobileSidebar, {
              [styles.visible]: popoverState.visible,
            })}
          >
            <Grid rowGap={2} display="flex" flexDirection="column">
              <ChainStatusInfo className={styles.mobileHidden} />
              <SwitchVariant />
              {renderNavigation()}
            </Grid>
          </div>
        )}
      </Grid>

      {isMobileSidebar && (
        <div
          className={cn(styles.overlay, {
            [styles.visible]: popoverState.visible,
          })}
          onClick={(e) => {
            e.stopPropagation()
            closeMenu()
          }}
        />
      )}
    </>
  )
}

export { Header }
