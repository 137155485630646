import { type ForwardedRef, forwardRef } from 'react'

import { OptionRootSlotProps } from '@mui/base/Option'
import cn from 'classnames'

import styles from './styles.module.scss'

const Option = forwardRef(function Option<TValue extends Record<string, unknown>>(
  props: OptionRootSlotProps<TValue>,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const { ownerState, ...other } = props

  return (
    <li
      {...other}
      ref={ref}
      className={cn(
        other.className,
        ownerState.selected && styles.selected,
        ownerState.highlighted && styles.highlighted,
      )}
    />
  )
})

export { Option }
