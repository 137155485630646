import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cn from 'classnames'

import { createLimitOrder } from '@/api/limit-order'
import { makeManualBuy } from '@/api/orders'
import { BuyTemplateSettingsList } from '@/components/buy-template-settings-list'
import { CustomToast } from '@/components/custom-toast'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import useCheckUser from '@/hooks/useCheckUser'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  Icon,
  Input,
  InputWithBuyAmount,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute, IconName, TransactionResponseType } from '@/libs/enums'
import { EBuyMode } from '@/libs/enums/buy-mode.enum.ts'
import { formatNumber } from '@/libs/helper'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { createLimitBuyPayload } from '@/libs/helper/buy/createLimitBuyPayload'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { validateRequiredFields } from '@/libs/helper/validateRequiredFields'
import { TBuyTransaction, TLimitBuyTransaction } from '@/libs/types/buy-transaction'
import { TBuyTemplate } from '@/libs/types/template'
import { getManualBuyValidationSchema } from '@/libs/validations/buy'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearCurrentSelectedTemplate, fetchPriorities } from '@/store/slices/user.slice'

import { PriorityCurrency } from '../priority-currency'
import { getDefaultTemplate } from './libs/utils/get-default-template'
import styles from './styles.module.scss'

type TProps = {
  usedInModal?: boolean
}

enum ELastUsedAmountInput {
  SPEND = 'spend',
  RECEIVE = 'receive',
}

const manualTemplate = {
  id: 'Manual',
  name: 'Manual',
  isPredefined: true,
}

const limitOptions = [
  { value: -10, label: '-10%' },
  { value: -20, label: '-20%' },
  { value: -30, label: '-30%' },
  { value: -40, label: '-40%' },
]

// const spendOptions = getBuyAmountOptions('ordinaryBuy.spend', ['0.1', '0.2', '0.5', '1'])

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const shieldsDefaultValues = {
  buy_tax: '',
  sell_tax: '',
  minimum_liquidity: '',
  maximum_liquidity: '',
  maximum_market_cap: '',
}

// All the commented fields will be used once the full version of the form is ready
const BuyTabForm: FC<TProps> = ({ usedInModal = false }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const userCurrentSelectedTemplate = useAppSelector(
    (state) => state.user.userCurrentSelectedTemplate,
  )
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const [defaultCustomTemplate, setDefaultCustomTemplate] = useState<typeof manualTemplate>()
  const [isPercentageUnit, setIsPercentageUnit] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false)
  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)

  const [spendOptions, setOptions] = useState<any>([])
  const checkUserAndExecute = useCheckUser()
  const navigate = useCustomNavigate()
  const quickBuySettings = useAppSelector((state) => state.user.quickBuySettings)

  const advancedBuyDefaultValues = {
    buyPriority: defaultUserPriorities?.buy_priority ?? '',
    approvePriority: defaultUserPriorities?.approve_priority ?? '',
    bribeAmount: defaultUserPriorities?.bribe_amount ?? '',
    minPercentTokenOrFail: '',
    maxTxOrFail: false,
  }

  useEffect(() => {
    if (!userData) return
    const setPriorities = async () => {
      const data = await dispatch(fetchPriorities(null)).unwrap()
      setValue('advancedBuy.buyPriority', data.buy_priority)
      setValue('advancedBuy.approvePriority', data.approve_priority)
      setValue('advancedBuy.bribeAmount', data.bribe_amount)
      setOptions(
        Object.entries(data)
          .filter(([key]) => key.startsWith('pro_'))
          .map(([, value]) => value?.toString()),
      )
    }
    setPriorities()

    setValue('ordinaryBuy.slippage', currentChain.defaultValues.slippage)
    setValue('ordinaryBuy.privateTransaction', !currentChain.features?.noPrivateTx)
    setValue('ordinaryBuy.selectedWallets', [])
  }, [currentChain.id])

  useEffect(() => {
    const defaultCustomTemplate = getDefaultTemplate()

    if (defaultCustomTemplate) {
      const { buyPriority, approvePriority } = getPrioritiesDefault()

      defaultCustomTemplate.setup.operation.gas.buy = {
        ...defaultCustomTemplate.setup.operation.gas.buy,
        miner_tip: currentChain.features?.noPriorityGas ? 0 : +buyPriority,
        gas: currentChain.features?.noPriorityGas ? +buyPriority : 10,
      }
      defaultCustomTemplate.setup.operation.gas.approve = {
        ...defaultCustomTemplate?.setup.operation.gas.approve,
        miner_tip: currentChain.features?.noPriorityGas ? 0 : +approvePriority,
        gas: currentChain.features?.noPriorityGas ? +approvePriority : 10,
      }

      setDefaultCustomTemplate(defaultCustomTemplate)
      if (!touchedFields?.advancedBuy?.buyPriority) {
        setValue('advancedBuy.buyPriority', defaultUserPriorities?.buy_priority || '')
      }
      if (!touchedFields?.advancedBuy?.approvePriority) {
        setValue('advancedBuy.approvePriority', defaultUserPriorities?.approve_priority || '')
      }
    }
  }, [defaultUserPriorities])

  const templates = useMemo(() => {
    const defaultTemplates = [manualTemplate]
    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate)
    }

    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.setup.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, defaultCustomTemplate, currentChain.id])

  const [currentTemplate, setCurrentTemplate] = useState<
    (TBuyTemplate & { isPredefined?: boolean }) | null
  >((templates as any)[1]?.isPredefined ? (templates[1] as any) : null)
  // const [footerTab, setFooterTab] = useState<string>('market')
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  // const [isAdvancedSettings, setIsAdvancedSetting] = useState(false)
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  // const [walletsOnProfit, setWalletsOnProfit] = useState<number[]>(
  //   defaultValues.walletsToSellOnProfit,
  // )
  const [customizableTemplateId, setCustomizableTemplateId] = useState<string | null>(null)
  const [availableTokens, setAvailableTokens] = useState(0)
  const [isDisabled, setIsDisabled] = useState(true)

  const ordinaryBuyDefaultValues = useMemo(() => {
    const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.is_default)

    if (defaultWallet) {
      setAvailableTokens(+(defaultWallet.balanceFormatted || 0))
    }
    // const spend = quickBuySettings.isInitialized ? quickBuySettings.settings.spend : ''

    return {
      privateTransaction: !currentChain.features?.noPrivateTx,
      degenChadMode: false,
      slippage: currentChain.defaultValues.slippage,
      // buyPriority: '',
      selectedWallets: defaultWallet ? [defaultWallet.address] : [],
      // onLimit: {
      //   dip: '',
      //   marketcap: '',
      //   price: '',
      //   triggerPricePercent: '',
      //   expiration: '',
      // },
      spend: '',
      receive: '',
    }
  }, [currentTemplate, currentChain, currentToken?.token])

  const defaultValues = useMemo(() => {
    return {
      ordinaryBuy: {
        ...ordinaryBuyDefaultValues,
        transactionType: 'market' as 'market' | 'limit',
        onLimit: {
          dip: '',
          marketcap: '',
          price: '',
          triggerPricePercent: '',
        },
        expiration: '',
      },
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
    }
  }, [currentTemplate, currentToken?.token])

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(getManualBuyValidationSchema(availableTokens)) as any,
  })

  useEffect(() => {
    if (currentTemplate) {
      const buyMode = currentTemplate?.setup?.operation?.setup?.buy_mode
      setIsPercentageUnit(buyMode === EBuyMode.MIN_PERCENT)
      setValue(
        'ordinaryBuy.selectedWallets',
        currentTemplate.setup.wallets.map((wallet) => wallet.public_key),
      )
    }
  }, [currentTemplate])

  const degenChadMode = watch('ordinaryBuy.degenChadMode')
  const transactionType = watch('ordinaryBuy.transactionType')
  const onLimitPrice = watch('ordinaryBuy.onLimit.price')
  // const spendAmount = watch('ordinaryBuy.spend')
  const minimumReceive =
    +watch('ordinaryBuy.receive') -
    ((currentTemplate
      ? currentTemplate.setup.operation.setup.slippage
      : +watch('ordinaryBuy.slippage')) /
      100) *
      +watch('ordinaryBuy.receive')

  const fieldsRequiredForBuyActions = watch(['ordinaryBuy.slippage', 'ordinaryBuy.selectedWallets'])

  const handleTriggerPriceCalculation = (
    type: 'price' | 'marketcap' | 'percent',
    value: string | number,
    setValue: any,
  ) => {
    if (!value || !currentToken?.token) {
      setValue('ordinaryBuy.onLimit.price', '')
      setValue('ordinaryBuy.onLimit.marketcap', '')
      setValue('ordinaryBuy.onLimit.triggerPricePercent', '')
      return
    }

    const currentPrice = +currentToken.token.price
    const currentMC = simulation?.market_cap ? +simulation.market_cap : 0

    if (type === 'price') {
      const newPrice = +value
      const newMC = (newPrice * currentMC) / currentPrice
      setValue(
        'ordinaryBuy.onLimit.marketcap',
        (+newMC).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      )
      setValue('ordinaryBuy.onLimit.triggerPricePercent', '')
    } else if (type === 'marketcap') {
      const mcValue = +value
      const calculatedPrice = (mcValue * currentPrice) / currentMC
      setValue('ordinaryBuy.onLimit.price', convertScientificNotationNumber(calculatedPrice))
      setValue('ordinaryBuy.onLimit.triggerPricePercent', '')
    } else if (type === 'percent') {
      const percentChange = +value / 100
      const priceWithPercent = currentPrice * (1 + percentChange)
      const mcWithPercent = currentMC * (1 + percentChange)

      setValue('ordinaryBuy.onLimit.price', convertScientificNotationNumber(priceWithPercent))
      setValue(
        'ordinaryBuy.onLimit.marketcap',
        (+mcWithPercent).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      )
      setValue('ordinaryBuy.onLimit.triggerPricePercent', value)
    }
  }

  // TODO: Move this logic with 2 inputs to a separate component
  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SPEND) {
      const spendValue = getValues().ordinaryBuy.spend
      if (spendValue) {
        calculateReceive(spendValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().ordinaryBuy.receive
      if (receiveValue) {
        calculateSpend(receiveValue)
      }
    }
  }, [currentChain])

  useEffect(() => {
    if (quickBuySettings.isInitialized && quickBuySettings.settings.spend) {
      const newValue = convertScientificNotationNumber(
        quickBuySettings.settings.spend,
        MAX_TRX_DECIMALS,
      )
      calculateReceive(newValue)
    }
  }, [currentToken?.token])

  useEffect(() => {
    if (currentTemplate) {
      setIsDisabled(false)
      return
    }
    setIsDisabled(validateRequiredFields(fieldsRequiredForBuyActions))
  }, [fieldsRequiredForBuyActions, currentTemplate])

  useEffect(() => {
    if (currentTemplate) {
      const newTemplate = templates.find((item) => item.id === currentTemplate.id)
      handleTemplateChange(newTemplate?.id)
    } else if (quickBuySettings?.isInitialized) {
      const quickSettingsTemplate = templates.find(
        (template) => template.id == quickBuySettings.settings.templateId,
      )
      quickSettingsTemplate && handleTemplateChange(quickSettingsTemplate?.id)
    }
  }, [templates])

  useEffect(() => {
    setAvailableTokens(handleAvailableTokens(currentTemplate))
  }, [userWallets])

  const handleAvailableTokens = (customTemplate?: TBuyTemplate | null) => {
    if (!userWallets) return 0

    let newAvailable = 0
    const templateToUse = customTemplate === null ? null : customTemplate || currentTemplate

    if (templateToUse) {
      templateToUse.setup.wallets.forEach((templateWallet) => {
        const wallet = userWallets.find(
          (userWallet) => userWallet.address === templateWallet.public_key,
        )
        newAvailable += +(wallet?.balanceFormatted || 0)
      })
    } else {
      getValues().ordinaryBuy.selectedWallets.forEach((public_key) => {
        newAvailable += +(
          userWallets.find((item) => item.address === public_key)?.balanceFormatted || 0
        )
      })
    }

    return newAvailable
  }

  const updateBuyAmount = (template: TBuyTemplate | null) => {
    if (template && template.setup) {
      const newBuyAmount = template?.setup.operation.setup.buy_amount
      calculateReceive(newBuyAmount ? convertScientificNotationNumber(newBuyAmount) : '')
    } else {
      if (!quickBuySettings.isInitialized) {
        setValue('ordinaryBuy.spend', '')
        calculateSpend('')
      }
    }
  }

  const handleTemplateChange = (newId?: string) => {
    const foundTemplate = templates.find((item) => item.id === newId)
    const newTemplate =
      foundTemplate && foundTemplate.id !== manualTemplate.id
        ? (foundTemplate as TBuyTemplate)
        : null

    updateBuyAmount(newTemplate)
    setCurrentTemplate(newTemplate)
    setAvailableTokens(handleAvailableTokens(newTemplate))
  }

  const customizeTemplate = () => {
    if (!currentTemplate) return

    const newValues = createBuyFormStateFromTemplate(currentTemplate).template

    Object.keys(newValues).forEach((key: any) => {
      setValue(key, (newValues as any)[key])
    })

    setCustomizableTemplateId(currentTemplate.id)
    handleTemplateChange(manualTemplate.id)
    updateBuyAmount(currentTemplate)
  }

  const calculateSpend = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setValue('ordinaryBuy.receive', `${value}`, {
      shouldValidate: !!value,
    })
    if (!value) {
      setValue('ordinaryBuy.spend', '')
      clearErrors('ordinaryBuy')
      return
    }
    const coeff = +currentToken?.token.price / currentChain.nativeTokenPriceInUsd
    setValue(
      'ordinaryBuy.spend',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const calculateReceive = (value: string) => {
    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
    setValue('ordinaryBuy.spend', `${value}`, {
      shouldValidate: !!value,
    })
    if (!value) {
      setValue('ordinaryBuy.receive', '')
      clearErrors('ordinaryBuy')
      return
    }
    let coeff = 0
    if (transactionType === 'market') {
      coeff = currentChain.nativeTokenPriceInUsd / +currentToken.token.price
    } else {
      coeff = onLimitPrice ? currentChain.nativeTokenPriceInUsd / +onLimitPrice : 0
    }
    setValue(
      'ordinaryBuy.receive',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userWallets || !userData || !currentToken || !simulation) return

    try {
      setIsLoading(true)

      let payload
      let response
      if (data.ordinaryBuy.transactionType === 'limit') {
        payload = {
          ...(currentTemplate?.setup ||
            createLimitBuyPayload({
              data,
              wallets: data.ordinaryBuy.selectedWallets.map((publicKey) => {
                const wallet = userWallets.find((item) => item.address === publicKey)
                return {
                  name: wallet?.name || '',
                  public_key: publicKey,
                }
              }),
              pair: currentToken.pair.address,
              price_usd: +data.ordinaryBuy.onLimit.price,
              expiration: Number(data.ordinaryBuy.expiration),
            })),
        } as TLimitBuyTransaction
        if (!payload) {
          CustomToast('error', 'Something went wrong')
          return
        }
        response = await createLimitOrder(payload)
      } else {
        // Handle market order
        if (!currentTemplate) {
          const wallets = data.ordinaryBuy.selectedWallets.map((publicKey) => {
            const wallet = userWallets.find((item) => item.address === publicKey)
            return {
              name: wallet?.name || '',
              public_key: publicKey,
            }
          })

          payload = createManualBuyPayload({
            data,
            wallets,
            percentMode: isPercentageUnit,
            degenMode: currentChain.features?.degenMode && degenChadMode,
          }) as TBuyTransaction
        } else {
          payload = { ...currentTemplate.setup }
          payload.token = currentToken.token.address
          payload.network = { ...payload.network, dex: +simulation?.liquidity.suggested_dex }
          payload.operation = {
            ...payload.operation,
            setup: {
              ...payload.operation.setup,
              buy_amount: +convertScientificNotationNumber(
                +data.ordinaryBuy.spend,
                MAX_TRX_DECIMALS,
              ),
            },
          }
        }

        if (!payload) {
          CustomToast('error', 'Something went wrong')
          return
        }
        response = await makeManualBuy(payload)
      }
      processTransactionResponse(response?.data, TransactionResponseType.BUY)
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userCurrentSelectedTemplate.id) {
      const template = userTemplates?.find(({ id }) => id === userCurrentSelectedTemplate.id)
      if (template) setCurrentTemplate(template)
      setIsSettingsExpanded(true)
    }
  }, [userCurrentSelectedTemplate])

  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 630
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={cn(styles.container, { [styles.usedInModal]: usedInModal })}>
        <div className={styles.content}>
          <Grid>
            <TemplatesControl
              templates={templates}
              currentTemplateId={currentTemplate?.id || manualTemplate.id}
              onSelectTemplate={handleTemplateChange}
              onCustomize={customizeTemplate}
              customizeDisabled={!currentTemplate}
              editDisabled={!currentTemplate || currentTemplate.isPredefined}
              deleteDisabled={!currentTemplate || currentTemplate.isPredefined}
              isResponsive={isResponsive}
            />
          </Grid>

          <div
            onClick={() =>
              checkUserAndExecute(() =>
                navigate({
                  isDashboard: true,
                  path: `${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`,
                }),
              )
            }
            className={styles.link}
          >
            <Typography variant="body2">
              {getChainSensitiveLabel('setupDefaultPriorities')} <Icon name={IconName.SETTING_2} />
            </Typography>
          </div>

          {!currentTemplate && (
            <>
              <Accordion
                className={styles.accordion}
                titleClassName={styles.accordionTitle}
                title={t('template.advanced_manual_buy_settings')}
                withDivider
                mainColorArrow
                // onChange={setIsAdvancedSetting}
              >
                <Grid container rowGap={3} flexDirection="column" marginTop={1}>
                  <Grid container rowGap={2}>
                    <Typography variant="body2" textColor="white">
                      {t('buy')}
                    </Typography>

                    {!!currentChain.features?.approvePriority && (
                      <Controller
                        name="advancedBuy.approvePriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={styles.input}
                            label={getChainSensitiveLabel('approvePriority')}
                            placeholder={t('template.enter_gwei_amount', {
                              currency: currentChain.priorityCurrency.toLowerCase(),
                            })}
                            isNumeric
                            tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                            endAdornment={<PriorityCurrency />}
                            error={!!errors.advancedBuy?.approvePriority?.message}
                            {...field}
                          />
                        )}
                      />
                    )}

                    <Controller
                      name="advancedBuy.minPercentTokenOrFail"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label={t('amount_values.exact_percentage_tokens')}
                          placeholder={t('amount_values.enter_token_amount_or_percentage')}
                          isNumeric
                          tooltipInfo={t('amount_values.enter_token_amount_or_percentage_tooltip')}
                          endAdornment={
                            <Button
                              className={cn(
                                styles.toggleOption,
                                isPercentageUnit && styles.activeToggleOption,
                              )}
                              transparent
                              onClick={() => {
                                setIsPercentageUnit(!isPercentageUnit)
                              }}
                            >
                              %
                            </Button>
                          }
                          disabled={watch('advancedBuy.maxTxOrFail')}
                          error={!!errors.advancedBuy?.minPercentTokenOrFail?.message}
                          {...field}
                        />
                      )}
                    />

                    {!!currentChain.features?.maxTxOrFail && (
                      <Controller
                        name="advancedBuy.maxTxOrFail"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label={t('template.max_tx_or_fail')}
                            tooltipInfo={t('template.max_tx_or_fail_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="white">
                      {t('template.safety')}
                    </Typography>

                    {/* <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="transferOnBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Transfer On Blacklist"
                            tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="antiBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Anti-Blacklist"
                            tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAntiRugSettings}
                      trigger={
                        <Controller
                          name="antiRug"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label="Anti-Rug"
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAntiRugSettings(checked)
                                onChange(checked)
                              }}
                              {...field}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2}>
                        <Controller
                          name="antiRugThreshold"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label="Anti-Rug Threshold"
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="sellRugExtraTip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Amount"
                              isNumeric
                              isNumeric
                              label="Sell Rug Extra Tip"
                              tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </HiddenInputs> */}

                    {(currentChain.features?.maxBuyTaxForTemplates ||
                      currentChain.features?.maxSellTaxForTemplates) && (
                      <Grid
                        container
                        flexWrap="nowrap"
                        gap={3}
                        flexDirection={isResponsive ? 'column' : 'row'}
                      >
                        {!!currentChain.features?.maxBuyTaxForTemplates && (
                          <Controller
                            name="shields.buy_tax"
                            control={control}
                            disabled={degenChadMode}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('template.enter_percentage')}
                                isNumeric
                                label={t('template.max_buy_tax')}
                                adornmentText="%"
                                tooltipInfo={t('template.max_buy_tax_tooltip')}
                                error={!!errors.shields?.buy_tax?.message}
                                {...field}
                              />
                            )}
                          />
                        )}

                        {!!currentChain.features?.maxSellTaxForTemplates && (
                          <Controller
                            name="shields.sell_tax"
                            control={control}
                            disabled={degenChadMode}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('template.enter_percentage')}
                                isNumeric
                                label={t('template.max_sell_tax')}
                                adornmentText="%"
                                tooltipInfo={t('template.max_sell_tax_tooltip')}
                                error={!!errors.shields?.sell_tax?.message}
                                {...field}
                              />
                            )}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid
                      container
                      flexWrap="nowrap"
                      gap={3}
                      flexDirection={isResponsive ? 'column' : 'row'}
                    >
                      <Controller
                        name="shields.minimum_liquidity"
                        disabled={degenChadMode}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_min_liquidity')}
                            isNumeric
                            label={t('token_info.min_liquidity')}
                            endAdornment={
                              <EndAdornment
                                label={currentChain.chainSymbol}
                                icon={(IconName as any)[currentChain.iconName]}
                              />
                            }
                            tooltipInfo={t('token_info.min_liquidity_tooltip')}
                            error={!!errors.shields?.minimum_liquidity?.message}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="shields.maximum_liquidity"
                        control={control}
                        disabled={degenChadMode}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_max_liquidity')}
                            isNumeric
                            label={t('token_info.max_liquidity')}
                            endAdornment={
                              <EndAdornment
                                label={currentChain.chainSymbol}
                                icon={(IconName as any)[currentChain.iconName]}
                              />
                            }
                            tooltipInfo={t('token_info.max_liquidity_tooltip')}
                            error={!!errors.shields?.maximum_liquidity?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Controller
                      name="shields.maximum_market_cap"
                      control={control}
                      disabled={degenChadMode}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder={t('token_info.enter_max_marketcap')}
                          isNumeric
                          label={t('token_info.max_marketcap')}
                          adornmentText="$"
                          tooltipInfo={t('token_info.max_marketcap_tooltip')}
                          error={!!errors.shields?.maximum_market_cap?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="light-grey">
                      Sell
                    </Typography>
                    <HiddenInputs
                      expanded={isAutoSellSettings}
                      trigger={
                        <Controller
                          name="autoSell"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label="Auto Sell"
                              tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                              {...field}
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAutoSellSetting(checked)
                                onChange(checked)
                              }}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2} flexDirection="column">
                        <Controller
                          name="sellPriority"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter gwei amount"
isNumeric
                              label="Sell Priority"
                              tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                              endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                              onAutoChange={() => {
                                //TO DO: added auto switch
                              }}
                              {...field}
                            />
                          )}
                        />
                        <div className={styles.dividerContainer}>
                          <Typography variant="body2" textColor="grey">
                            Profit
                          </Typography>
                          <div className={styles.divider}></div>
                        </div>

                        <Controller
                          name="walletsToSellOnProfit"
                          control={control}
                          render={({ field: { ref, value, onChange, ...field } }) => (
                            <SelectWallet
                              tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                              label="Wallets to sell on Profit"
                              isMulti
                              wallet={value}
                              wallets={userWallets}
                              onChange={(value) => {
                                setWalletsOnProfit(value)
                                onChange(value)
                              }}
                              {...field}
                            />
                          )}
                        />

                        <ButtonGroupRadio
                          onChange={handleProfitTabChange}
                          value={profitTab}
                          exclusive
                          className={styles.group}
                        >
                          {ProfitSettingsTabs.map((tab) => (
                            <ButtonGroupRadioButton
                              value={tab.value}
                              key={tab.value}
                              className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                            >
                              {tab.label}
                            </ButtonGroupRadioButton>
                          ))}
                        </ButtonGroupRadio>

                        {profitTab === ProfitSettingsTabs[0].value && (
                          <div>
                            <div className={styles.labelContent}>
                              <InputLabel className={styles.label}>
                                <Typography variant="body2" textColor="light-grey">
                                  Sell in steps
                                </Typography>
                              </InputLabel>
                              <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                            </div>
                            {walletsOnProfit.length !== 0 ? (
                              <div className={styles.stepWrapper}>
                                {walletsOnProfit.map((wallet, index) => (
                                  <Controller
                                    key={wallet}
                                    name={`w${wallet}` as keyof typeof defaultValues}
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <StepInput
                                        wallet="#1"
                                        balance="12.58 ETH"
                                        className={cn({
                                          [styles.firstInput]: index === 0,
                                          [styles.lastInput]: index === walletsOnProfit.length - 1,
                                        })}
                                        {...field}
                                      />
                                    )}
                                  />
                                ))}
                              </div>
                            ) : (
                              <Grid marginTop={1}>
                                <Typography variant="body1">No wallets selected</Typography>
                              </Grid>
                            )}
                          </div>
                        )}
                        {profitTab === ProfitSettingsTabs[1].value && (
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="sellOnProfit"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter sell on profit"
                                  isNumeric
                                  label="Sell on Profit"
                                  adornmentText="%"
                                  tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="sellOnProfitAmount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter sell profit amount"
                                  isNumeric
                                  label="Sell Profit Amount"
                                  adornmentText="%"
                                  tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {profitTab === ProfitSettingsTabs[2].value ? (
                          <>
                            <Controller
                              name="walletsToTrailingSell"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  isMulti
                                  wallet={value}
                                  label="Wallets to trailing sell"
                                  tooltipInfo="Select which wallets will be used to purchase token."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="trailingSell"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter trailing sell"
                                  isNumeric
                                  label="Trailing Sell"
                                  adornmentText="%"
                                  tooltipInfo="tooltipInfo"
                                  {...field}
                                />
                              )}
                            />
                          </>
                        ) : (
                          <>
                            <div className={styles.dividerContainer}>
                              <Typography variant="body2" textColor="grey">
                                Loss
                              </Typography>
                              <div className={styles.divider}></div>
                            </div>

                            <Controller
                              name="walletsToSellOnLoss"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  label="Wallets to sell on Loss"
                                  tooltipInfo="Select which wallets will be used to purchase token."
                                  isMulti
                                  wallet={value}
                                  wallets={userWallets}
                                  {...field}
                                />
                              )}
                            />

                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="stopLoss"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter amount"
                                    label="Stop Loss"
                                    tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="stopLossAmount"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter amount"
                                    label="Stop Loss Amount"
                                    tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </HiddenInputs>
                  </Grid> */}
                </Grid>
              </Accordion>

              <Grid
                container
                gap={3}
                flexWrap="nowrap"
                flexDirection={isResponsive ? 'column' : 'row'}
              >
                {!currentChain.features?.noPrivateTx && (
                  <Controller
                    name="ordinaryBuy.privateTransaction"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <SwitchInline
                        label={t('template.anti_mev')}
                        tooltipInfo={t('template.anti_mev_tooltip')}
                        {...field}
                      />
                    )}
                  />
                )}

                {!!currentChain.features?.degenMode && (
                  <Controller
                    name="ordinaryBuy.degenChadMode"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <SwitchInline
                        label={t('template.degen_chad_mode')}
                        tooltipInfo={t('template.degen_chad_mode_tooltip')}
                        {...field}
                      />
                    )}
                  />
                )}
              </Grid>

              <Controller
                name="ordinaryBuy.slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label={t('template.slippage')}
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo={t('template.slippage_tooltip')}
                    placeholder="X"
                    error={!!errors.ordinaryBuy?.slippage?.message}
                    {...field}
                  />
                )}
              />

              {!!currentChain.features?.buyPriority && (
                <Controller
                  name="advancedBuy.buyPriority"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Grid position="relative">
                      {/* <div
                        onClick={() =>
                          checkUserAndExecute(() =>
                            navigate({
                              isDashboard: true,
                              path: `${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`,
                            }),
                          )
                        }
                        className={cn(styles.link, styles.defaultPriorities)}
                      >
                        <Typography variant="body2">
                          {getChainSensitiveLabel('setupDefaultPriorities')}{' '}
                          <Icon name={IconName.SETTING_2} />
                        </Typography>
                      </div> */}
                      <OptionalInput
                        className={styles.input}
                        label={getChainSensitiveLabel('buyPriority')}
                        placeholder={t('template.enter_gwei_amount', {
                          currency: currentChain.priorityCurrency.toLowerCase(),
                        })}
                        isNumeric
                        tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                        endAdornment={<PriorityCurrency />}
                        error={!!errors.advancedBuy?.buyPriority?.message}
                        {...field}
                      />
                    </Grid>
                  )}
                />
              )}

              {!!currentChain.features?.bribeAmount && (
                <Controller
                  name="advancedBuy.bribeAmount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      className={styles.input}
                      label={getChainSensitiveLabel('bribeAmount')}
                      tooltipInfo={t('token_info.bribe_amount_tooltip')}
                      placeholder={t('token_info.bribe_amount_placeholder')}
                      isNumeric
                      endAdornment={<PriorityCurrency />}
                      error={!!errors.advancedBuy?.bribeAmount?.message}
                      {...field}
                    />
                  )}
                />
              )}

              <Controller
                name="ordinaryBuy.selectedWallets"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => {
                  // to fix bug when value was changed and render prop wasn't updated
                  const selectedWallets = getValues().ordinaryBuy.selectedWallets

                  return (
                    <SelectWallet
                      tooltipInfo={t('wallet.select_wallets_tooltip')}
                      isMulti
                      wallet={selectedWallets}
                      wallets={userWallets}
                      onChange={(value) => {
                        onChange(value)
                        setAvailableTokens(handleAvailableTokens())
                      }}
                      isSelectDefaultWallet={!customizableTemplateId}
                      {...field}
                    />
                  )
                }}
              />
            </>
          )}

          {currentTemplate && (
            <BuyTemplateSettingsList
              template={currentTemplate as TBuyTemplate}
              expanded={isSettingsExpanded}
              onChange={(expanded) => {
                setIsSettingsExpanded(expanded)
                dispatch(clearCurrentSelectedTemplate())
              }}
            />
          )}
          <Controller
            name="ordinaryBuy.transactionType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ButtonGroupRadio
                exclusive
                onChange={(_, newValue) => onChange(newValue)}
                value={value}
              >
                <ButtonGroupRadioButton value="market" name="market" className={styles.radioButton}>
                  <Typography variant="caption" textColor="white" className={styles.buttonContent}>
                    {t('limit_order.market')}
                  </Typography>
                </ButtonGroupRadioButton>
                <ButtonGroupRadioButton value="limit" name="limit" className={styles.radioButton}>
                  <Typography variant="caption" textColor="white" className={styles.buttonContent}>
                    {t('limit_order.limit')}
                  </Typography>
                </ButtonGroupRadioButton>
              </ButtonGroupRadio>
            )}
          />
          {transactionType === 'limit' && (
            <>
              <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                <div>
                  <Typography variant="body2" textColor="light-grey">
                    {t('limit_order.trigger_price')}
                  </Typography>
                  <div className={styles.inputWrapper}>
                    {/* <Controller
                      name="ordinaryBuy.onLimit.dip"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cn(styles.input, styles.first)}
                          placeholder="Enter dip %"
                          isNumeric
                          endAdornment={<EndAdornment label="%" />}
                          {...field}
                        />
                      )}
                    /> */}
                    <Controller
                      name="ordinaryBuy.onLimit.marketcap"
                      control={control}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Input
                          className={cn(styles.input, styles.first)}
                          placeholder={t('limit_order.enter_market_cap')}
                          isNumeric
                          endAdornment={<EndAdornment label="MC" />}
                          onChange={(e) => {
                            onChange(e)
                            handleTriggerPriceCalculation('marketcap', e.target.value, setValue)
                          }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="ordinaryBuy.onLimit.price"
                      control={control}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Input
                          className={cn(styles.input, styles.last)}
                          placeholder={t('limit_order.enter_price')}
                          isNumeric
                          endAdornment={<EndAdornment label="$" />}
                          onChange={(e) => {
                            onChange(e)
                            handleTriggerPriceCalculation('price', e.target.value, setValue)
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="ordinaryBuy.onLimit.triggerPricePercent"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <ButtonGroupRadio
                        className={styles.radioGroup}
                        onChange={(_, value: string) => {
                          onChange(value)
                          handleTriggerPriceCalculation('percent', value, setValue)
                        }}
                        exclusive
                        {...field}
                      >
                        {limitOptions.map((option) => (
                          <ButtonGroupRadioButton
                            value={option.value}
                            className={styles.radioButton}
                            key={option.value}
                          >
                            {option.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>
                    )}
                  />
                </div>
              </Grid>
              <Controller
                name="ordinaryBuy.expiration"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    label={t('limit_order.expiration')}
                    className={cn(styles.input, styles.last)}
                    placeholder={t('limit_order.expiration_placeholder')}
                    isNumeric
                    endAdornment={<EndAdornment label="Hours" isLowercase={true} />}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </div>

        <div className={styles.footer}>
          <div className={styles.arrow}>
            <Icon name={IconName.ARROW_DOWN_GREY} />
          </div>

          <div className={styles.container}>
            <div className={styles.spend}>
              <Controller
                name="ordinaryBuy.spend"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => {
                  return (
                    <InputWithBuyAmount
                      label={t('spend')}
                      placeholder={t('amount_values.amount_to_spend')}
                      isNumeric
                      className={styles.input}
                      onOptionSelect={(value) => {
                        const newValue = convertScientificNotationNumber(value, MAX_TRX_DECIMALS)
                        setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
                        calculateReceive(newValue)
                      }}
                      endAdornment={
                        <Grid display="flex" alignItems="center" columnGap={2}>
                          <Typography fontWeight={400} whiteSpace="nowrap">
                            ($
                            {
                              formatNumber(
                                +field.value * (currentChain.nativeTokenPriceInUsd || 0),
                                2,
                              ).formatted
                            }
                            )
                          </Typography>
                          <EndAdornment
                            label={currentChain.chainSymbol}
                            icon={(IconName as any)[currentChain.iconName]}
                            isDisabled={isDisabled}
                          />
                        </Grid>
                      }
                      radioGroupName="amount"
                      disabled={isDisabled}
                      defaultOptions={spendOptions}
                      onChange={(e) => {
                        if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                        setLastUsedAmountInput(ELastUsedAmountInput.SPEND)
                        if (!e.target.value) {
                          calculateReceive(e.target.value)
                          return
                        }
                        const newValue = convertScientificNotationNumber(
                          e.target.value,
                          MAX_TRX_DECIMALS,
                          true,
                        )
                        calculateReceive(newValue)
                      }}
                      error={!!errors.ordinaryBuy?.spend?.message}
                      {...field}
                    />
                  )
                }}
              />
              <div className={styles.available}>
                <Typography variant="body2" className={styles.param}>
                  {t('available')}:
                </Typography>
                <Typography variant="body2" className={styles.value}>
                  {(+availableTokens || 0).toLocaleString('en-US', {
                    maximumFractionDigits: 5,
                  })}{' '}
                  {currentChain.chainSymbol}
                </Typography>
              </div>
            </div>

            <div className={styles.union}>
              <Controller
                name="ordinaryBuy.receive"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Input
                    label={t('receive')}
                    placeholder={t('amount_values.amount_to_receive')}
                    isNumeric
                    className={styles.input}
                    endAdornment={
                      <EndAdornment
                        label={currentToken?.token.symbol}
                        imageUrl={currentToken?.token.images.small}
                        isDisabled={isDisabled}
                      />
                    }
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                      setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
                      if (!e.target.value) {
                        calculateSpend(e.target.value)
                        return
                      }
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      calculateSpend(newValue)
                    }}
                    error={!!errors.ordinaryBuy?.receive?.message}
                    {...field}
                  />
                )}
              />
              <div className={cn(styles.infoContainer, { [styles.disabled]: isDisabled })}>
                {!!simulation?.liquidity.suggested_dex && (
                  <div className={styles.info}>
                    <Typography variant="body2" className={styles.param}>
                      DEX:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      {simulation?.liquidity.suggested_dex}
                    </Typography>
                  </div>
                )}
                <div className={styles.info}>
                  <Typography variant="body2" className={styles.param}>
                    {t('token_info.minimum_received')}:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    {minimumReceive ? formatTokenPrice(minimumReceive.toString()).formatted : '-'}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <Button type="submit" disabled={isDisabled} isLoading={isLoading} checkOnAccountLock>
            {t('buy')}
          </Button>
        </div>
      </div>
    </form>
  )
}

const BuyTab: FC<TProps> = (props) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const { t } = useTranslation()

  if (!userWallets?.length) {
    return (
      <Typography variant="body1" textColor="grey" align="center" marginBottom="12px">
        {t('wallet.no_wallets_available')}
      </Typography>
    )
  }

  return <BuyTabForm {...props} />
}

export { BuyTab }
