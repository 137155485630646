import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { cancelOrder } from '@/api/limit-order'
import { CustomToast } from '@/components/custom-toast'
import { calculateMCFromPrice } from '@/components/dashboard-component-lite/libs/buy-sell-block/helpers/helpers'
import { Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TOpenLimitOrder } from '@/libs/types/limit-order.type'
import { useAppSelector } from '@/store'

import styles from '../../styles.module.scss'

type TProperty = {
  row: TOpenLimitOrder
  refetch: () => void
  setRows: React.Dispatch<React.SetStateAction<TOpenLimitOrder[]>>
}

const Row: FC<TProperty> = ({ row, refetch, setRows }) => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.adaptedCurrentTokenSimulationWebsocket)
  const { t } = useTranslation()
  const { trigger_type, trigger, status, token_name, token_symbol, created_at } = row
  const isBuy = trigger_type === 'Limit Buy' ? 'Buy at' : 'Sell at'
  const currentMarketCap = simulation?.data?.market_cap ?? 0
  const marketCap = calculateMCFromPrice(trigger.trigger.fixed.price_usd, +currentMarketCap)

  const handleCancelOrder = async () => {
    try {
      await cancelOrder(row.id)
      CustomToast('success', t('translation.info.order_cancelled'))
      refetch()
      setRows((prev: TOpenLimitOrder[]) =>
        prev.filter((item: TOpenLimitOrder) => item.id !== row.id),
      )
    } catch (error) {
      CustomToast('error', (error as any).message)
    }
  }

  return (
    <TableRow className={styles.row}>
      <TableCell className={styles.cell}>
        <div className={styles.token}>
          <button className={styles.cancelButton} onClick={handleCancelOrder}>
            <Icon name={IconName.DELETE_RED} />
          </button>
          {currentToken?.info.images.small ? (
            <img
              className={styles.tokenLogo}
              src={currentToken?.info.images.small}
              alt={currentToken?.info.name + ' token'}
            />
          ) : (
            <TokenPlaceholder />
          )}
          <div>
            <Typography variant="body2" className={cn(styles.cellText, styles.name)}>
              {token_name}
            </Typography>
            <Typography variant="caption" textColor="color-white-3">
              {token_symbol}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2">
          {formatNumber(trigger.buy_template.operation.setup.buy_amount).formatted}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2">
          {isBuy} {'<='} ${formatNumber(trigger.trigger.fixed.price_usd).formatted}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.priceCell}>
          <Typography variant="body2">MC ${formatTokenPrice(marketCap.toString()).raw}</Typography>
          <Typography variant="caption" textColor="color-white-3">
            ${formatNumber(trigger.trigger.fixed.price_usd).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.priceCell}>
          <Typography variant="body2">
            MC ${formatTokenPrice(currentMarketCap.toString()).formatted}
          </Typography>
          <Typography variant="caption" textColor="color-white-3">
            ${formatNumber(simulation?.data?.price.usd ?? 0).formatted}
          </Typography>
        </div>
      </TableCell>

      <TableCell className={styles.cell}>
        <Typography variant="body2">{status}</Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="body2">{trigger.buy_template.operation.setup.slippage}</Typography>
      </TableCell>

      <TableCell className={styles.cell}>
        <div className={styles.dateCell}>
          <Typography variant="body2">
            {moment.utc(created_at).format('MMM D, HH:mm:ss')}
          </Typography>
          <Typography variant="caption" textColor="color-white-3">
            {(() => {
              const expirationTime = moment.unix(trigger.trigger.execution.expiration)
              const currentTime = moment.utc()
              const duration = moment.duration(expirationTime.diff(currentTime))
              return `Expires in ${duration.hours()}h ${duration.minutes()}m`
            })()}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
