import CachedIcon from '@mui/icons-material/Cached'
import SettingsIcon from '@mui/icons-material/Settings'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { ReactComponent as CoinETH } from '@/assets/icons/ETH.svg'
import { ReactComponent as AddCircle } from '@/assets/icons/add_circle.svg'
import { ReactComponent as Affiliate } from '@/assets/icons/affiliate.svg'
import { ReactComponent as Alarm } from '@/assets/icons/alarm.svg'
import { ReactComponent as Approve } from '@/assets/icons/approve.svg'
import { ReactComponent as ApproveBig } from '@/assets/icons/approve_big.svg'
import { ReactComponent as Arbitrum } from '@/assets/icons/arbitrum.svg'
import { ReactComponent as ArrowDownMinimalistic } from '@/assets/icons/arrow-down-minimalistic.svg'
import { ReactComponent as ArrowDown } from '@/assets/icons/arrow_down.svg'
import { ReactComponent as ArrowDownGrey } from '@/assets/icons/arrow_down_grey.svg'
import { ReactComponent as ArrowDropDown } from '@/assets/icons/arrow_drop_down.svg'
import { ReactComponent as ArrowDropDown2 } from '@/assets/icons/arrow_drop_down_2.svg'
import { ReactComponent as ArrowRight2 } from '@/assets/icons/arrow_right2.svg'
import { ReactComponent as ArrowRight } from '@/assets/icons/arrow_right.svg'
import { ReactComponent as ArrowRight3 } from '@/assets/icons/arrow_right_3.svg'
import { ReactComponent as ArrowRightGrey } from '@/assets/icons/arrow_right_grey.svg'
import { ReactComponent as ArrowUp } from '@/assets/icons/arrow_up.svg'
import { ReactComponent as AvaxChain } from '@/assets/icons/avax_chain.svg'
import { ReactComponent as BaseNetwork } from '@/assets/icons/base-network.svg'
import { ReactComponent as BaseChain } from '@/assets/icons/base_chain.svg'
import { ReactComponent as Block } from '@/assets/icons/block.svg'
import { ReactComponent as BNB } from '@/assets/icons/bnb.svg'
import { ReactComponent as BNB_V2 } from '@/assets/icons/bnb_v2.svg'
import { ReactComponent as Bolt } from '@/assets/icons/bolt.svg'
import { ReactComponent as BottomTabsBuy } from '@/assets/icons/bottom-tabs-buy.svg'
import { ReactComponent as BottomTabsProfit } from '@/assets/icons/bottom-tabs-profit.svg'
import { ReactComponent as BottomTabsSell } from '@/assets/icons/bottom-tabs-sell.svg'
import { ReactComponent as Bridge } from '@/assets/icons/bridge.svg'
import { ReactComponent as Bscscan } from '@/assets/icons/bscscan.svg'
import { ReactComponent as Bubble } from '@/assets/icons/bubble.svg'
import { ReactComponent as Burger } from '@/assets/icons/burger.svg'
import { ReactComponent as Buy } from '@/assets/icons/buy.svg'
import { ReactComponent as Cancel } from '@/assets/icons/cancel.svg'
import { ReactComponent as Candlestick } from '@/assets/icons/candlestick.svg'
import { ReactComponent as Check } from '@/assets/icons/check.svg'
import { ReactComponent as CheckSmall } from '@/assets/icons/check_small.svg'
import { ReactComponent as ChevronLeft } from '@/assets/icons/chevron_left.svg'
import { ReactComponent as ChevronRight } from '@/assets/icons/chevron_right.svg'
import { ReactComponent as Close } from '@/assets/icons/close.svg'
import { ReactComponent as CloseGrey } from '@/assets/icons/close_grey.svg'
import { ReactComponent as CloseMiddle } from '@/assets/icons/close_middle.svg'
import { ReactComponent as CloseSmall } from '@/assets/icons/close_small.svg'
import { ReactComponent as ConnectWallet } from '@/assets/icons/connect_wallet.svg'
import { ReactComponent as CopyGrey } from '@/assets/icons/copy-grey.svg'
import { ReactComponent as Copy } from '@/assets/icons/copy.svg'
import { ReactComponent as CopyLarge } from '@/assets/icons/copy_large.svg'
import { ReactComponent as Cross } from '@/assets/icons/cross.svg'
import { ReactComponent as CrossedStar } from '@/assets/icons/crossed_star.svg'
import { ReactComponent as Customize } from '@/assets/icons/customize.svg'
import { ReactComponent as Delete } from '@/assets/icons/delete.svg'
import { ReactComponent as DeleteRed } from '@/assets/icons/delete_red.svg'
import { ReactComponent as Deposit } from '@/assets/icons/deposit.svg'
import { ReactComponent as Diamond } from '@/assets/icons/diamond.svg'
import { ReactComponent as DoubleArrowLeft } from '@/assets/icons/double-arrow-left.svg'
import { ReactComponent as Download } from '@/assets/icons/download.svg'
import { ReactComponent as DropdownArrows } from '@/assets/icons/dropdown-arrows.svg'
import { ReactComponent as Edit } from '@/assets/icons/edit.svg'
import { ReactComponent as EditSmall } from '@/assets/icons/edit_small.svg'
import { ReactComponent as ErrorTx } from '@/assets/icons/error_tx.svg'
import { ReactComponent as EthCurrency } from '@/assets/icons/eth-currency.svg'
import { ReactComponent as Ethereum } from '@/assets/icons/ethereum.svg'
import { ReactComponent as Etherscan } from '@/assets/icons/etherscan.svg'
import { ReactComponent as EtherscanGold } from '@/assets/icons/etherscan_gold.svg'
import { ReactComponent as Exchange } from '@/assets/icons/exchange.svg'
import { ReactComponent as Exclude } from '@/assets/icons/exclude.svg'
import { ReactComponent as ExpandMore } from '@/assets/icons/expand_more.svg'
import { ReactComponent as FavoriteEth } from '@/assets/icons/favorite-eth.svg'
import { ReactComponent as FeesCollected } from '@/assets/icons/fees-collected.svg'
import { ReactComponent as Filter } from '@/assets/icons/filter.svg'
import { ReactComponent as Fire } from '@/assets/icons/fire.svg'
import { ReactComponent as Flip } from '@/assets/icons/flip.svg'
import { ReactComponent as Gas } from '@/assets/icons/gas.svg'
import { ReactComponent as GoldWallet } from '@/assets/icons/gold-wallet.svg'
import { ReactComponent as GroupOfPeople } from '@/assets/icons/group-of-people.svg'
import { ReactComponent as Hide } from '@/assets/icons/hide.svg'
import { ReactComponent as HoldingsGrey } from '@/assets/icons/holdings-grey.svg'
import { ReactComponent as InfoError } from '@/assets/icons/info_error.svg'
import { ReactComponent as InfoIcon } from '@/assets/icons/info_icon.svg'
import { ReactComponent as Key } from '@/assets/icons/key.svg'
import { ReactComponent as LanguageGold } from '@/assets/icons/language-gold.svg'
import { ReactComponent as Language } from '@/assets/icons/language.svg'
import { ReactComponent as ListAlt } from '@/assets/icons/list_alt.svg'
import { ReactComponent as ListAltAdd } from '@/assets/icons/list_alt_add.svg'
import { ReactComponent as ListPoint } from '@/assets/icons/list_point.svg'
import { ReactComponent as LockGreen } from '@/assets/icons/lock-green.svg'
import { ReactComponent as LockWithReflection } from '@/assets/icons/lock-with-reflection.svg'
import { ReactComponent as Lock } from '@/assets/icons/lock.svg'
import { ReactComponent as EtherLogo } from '@/assets/icons/logo-etherscan.svg'
import { ReactComponent as Logo } from '@/assets/icons/logo.svg'
import { ReactComponent as Mail } from '@/assets/icons/mail.svg'
import { ReactComponent as Manufacturing } from '@/assets/icons/manufacturing.svg'
import { ReactComponent as MetamaskLogo } from '@/assets/icons/metamask-logo.svg'
import { ReactComponent as MetaMask } from '@/assets/icons/metamask.svg'
import { ReactComponent as MiddleSuccess } from '@/assets/icons/middle_success.svg'
import { ReactComponent as More } from '@/assets/icons/more.svg'
import { ReactComponent as Pancakeswap } from '@/assets/icons/pancakeswap.svg'
import { ReactComponent as Pending } from '@/assets/icons/pending.svg'
import { ReactComponent as PendingBlue } from '@/assets/icons/pending_blue.svg'
import { ReactComponent as PendingOrange } from '@/assets/icons/pending_orange.svg'
import { ReactComponent as PEPE } from '@/assets/icons/pepe.svg'
import { ReactComponent as Phantom } from '@/assets/icons/phantom.svg'
import { ReactComponent as Phone } from '@/assets/icons/phone.svg'
import { ReactComponent as PowerSettings } from '@/assets/icons/power_settings.svg'
import { ReactComponent as PriceCheck } from '@/assets/icons/price_check.svg'
import { ReactComponent as ProfitSell } from '@/assets/icons/profit_sell.svg'
import { ReactComponent as QuickSettings } from '@/assets/icons/quick_settings.svg'
import { ReactComponent as Rabby } from '@/assets/icons/rabby-wallet.svg'
import { ReactComponent as Radar } from '@/assets/icons/radar.svg'
import { ReactComponent as Schedule } from '@/assets/icons/schedule.svg'
import { ReactComponent as Scrape } from '@/assets/icons/scrape.svg'
import { ReactComponent as Search } from '@/assets/icons/search.svg'
import { ReactComponent as Sell2 } from '@/assets/icons/sell2.svg'
import { ReactComponent as Sell } from '@/assets/icons/sell.svg'
import { ReactComponent as Setting } from '@/assets/icons/setting.svg'
import { ReactComponent as Share } from '@/assets/icons/share.svg'
import { ReactComponent as ShoppingCart } from '@/assets/icons/shopping_cart.svg'
import { ReactComponent as ShoppingCartLarge } from '@/assets/icons/shopping_cart_large.svg'
import { ReactComponent as Show } from '@/assets/icons/show.svg'
import { ReactComponent as SidebarCollapsedButton } from '@/assets/icons/sidebar-collapsed-button.svg'
import { ReactComponent as SmallSuccess } from '@/assets/icons/small_success.svg'
import { ReactComponent as Snipe } from '@/assets/icons/snipe.svg'
import { ReactComponent as SnipeLarge } from '@/assets/icons/snipe_large.svg'
import { ReactComponent as SnipeWhite } from '@/assets/icons/snipe_white.svg'
import { ReactComponent as Solana } from '@/assets/icons/solana.svg'
import { ReactComponent as Solscan } from '@/assets/icons/solscan.svg'
import { ReactComponent as SortAsc } from '@/assets/icons/sort_asc.svg'
import { ReactComponent as SortDesc } from '@/assets/icons/sort_desc.svg'
import { ReactComponent as StarGreyBold } from '@/assets/icons/star-grey-bold.svg'
import { ReactComponent as Star } from '@/assets/icons/star.svg'
import { ReactComponent as Success } from '@/assets/icons/success.svg'
import { ReactComponent as Switch } from '@/assets/icons/switch.svg'
import { ReactComponent as Telegram } from '@/assets/icons/telegram.svg'
import { ReactComponent as TelegramLink } from '@/assets/icons/telegram_link.svg'
import { ReactComponent as TelegramUnlink } from '@/assets/icons/telegram_unlink.svg'
import { ReactComponent as TemplatesGrey } from '@/assets/icons/templates-grey.svg'
import { ReactComponent as ToastError } from '@/assets/icons/toast_error.svg'
import { ReactComponent as ToastInfo } from '@/assets/icons/toast_info.svg'
import { ReactComponent as ToastSuccess } from '@/assets/icons/toast_success.svg'
import { ReactComponent as ToastWarning } from '@/assets/icons/toast_warning.svg'
import { ReactComponent as TokenBadge } from '@/assets/icons/token-badge.svg'
import { ReactComponent as Toll } from '@/assets/icons/toll.svg'
import { ReactComponent as TradingStatus } from '@/assets/icons/trading-status.svg'
import { ReactComponent as Transfer } from '@/assets/icons/transfer.svg'
import { ReactComponent as Trophy } from '@/assets/icons/trophy.svg'
import { ReactComponent as Twiter } from '@/assets/icons/twitter.svg'
import { ReactComponent as ArrowDown_ } from '@/assets/icons/universal/arrow-down.svg'
import { ReactComponent as Article_ } from '@/assets/icons/universal/article.svg'
import { ReactComponent as Check_ } from '@/assets/icons/universal/check.svg'
import { ReactComponent as Close_ } from '@/assets/icons/universal/close.svg'
import { ReactComponent as ElectricBoltOutlined_ } from '@/assets/icons/universal/electric-bolt-outlined.svg'
import { ReactComponent as ElectricBolt_ } from '@/assets/icons/universal/electric-bolt.svg'
import { ReactComponent as Gear_ } from '@/assets/icons/universal/gear.svg'
import { ReactComponent as Person_ } from '@/assets/icons/universal/person.svg'
import { ReactComponent as PumpFun_ } from '@/assets/icons/universal/pump-fun.svg'
import { ReactComponent as Restart_ } from '@/assets/icons/universal/restart.svg'
import { ReactComponent as Settings_ } from '@/assets/icons/universal/settings.svg'
import { ReactComponent as SolanaCircle_ } from '@/assets/icons/universal/solana-circle.svg'
import { ReactComponent as Solana_ } from '@/assets/icons/universal/solana.svg'
import { ReactComponent as UpdatePlan } from '@/assets/icons/update-plan.svg'
import { ReactComponent as VerifiedMail } from '@/assets/icons/verified_email.svg'
import { ReactComponent as Visibility } from '@/assets/icons/visibility.svg'
import { ReactComponent as WalletConnect } from '@/assets/icons/wallet-connect.svg'
import { ReactComponent as WalletGrey } from '@/assets/icons/wallet-grey.svg'
import { ReactComponent as Wallet } from '@/assets/icons/wallet.svg'
import { ReactComponent as Withdraw } from '@/assets/icons/withdraw.svg'
import { ReactComponent as XSquare } from '@/assets/icons/x_square.svg'
import { IconName } from '@/libs/enums'

const IconNameToIcon = {
  [IconName.POWER]: <PowerSettings />,
  [IconName.APPROVE]: <Approve />,
  [IconName.LOGO]: <Logo />,
  [IconName.MAIL]: <Mail />,
  [IconName.TOAST_SUCCESS]: <ToastSuccess />,
  [IconName.TOAST_ERROR]: <ToastError />,
  [IconName.TOAST_WARNING]: <ToastWarning />,
  [IconName.TOAST_INFO]: <ToastInfo />,
  [IconName.VERIFIED_MAIL]: <VerifiedMail />,
  [IconName.DOWNLOAD]: <Download />,
  [IconName.FLIP]: <Flip />,
  [IconName.PHONE]: <Phone />,
  [IconName.ARROW_RIGHT]: <ArrowRight />,
  [IconName.COPY]: <Copy />,
  [IconName.LIST_POINT]: <ListPoint />,
  [IconName.MANUFACTURING]: <Manufacturing />,
  [IconName.KEY]: <Key />,
  [IconName.LIST_ALT_ADD]: <ListAltAdd />,
  [IconName.INFO_ICON]: <InfoIcon />,
  [IconName.EDIT_SMALL]: <EditSmall />,
  [IconName.X_SQUARE]: <XSquare />,
  [IconName.SUCCESS]: <Success />,
  [IconName.GAS]: <Gas />,
  [IconName.BLOCK]: <Block />,
  [IconName.DIAMOND]: <Diamond />,
  [IconName.ARBITRUM]: <Arbitrum />,
  [IconName.BNB]: <BNB />,
  [IconName.BNB_V2]: <BNB_V2 />,
  [IconName.ETHEREUM]: <Ethereum />,
  [IconName.EXCHANGE]: <Exchange />,
  [IconName.CLOSE]: <Close />,
  [IconName.SNIPE]: <Snipe />,
  [IconName.COIN_ETH]: <CoinETH />,
  [IconName.METAMASK_LOGO]: <MetamaskLogo />,
  [IconName.LOCK]: <Lock />,
  [IconName.CROSS]: <Cross />,
  [IconName.WITHDRAW]: <Withdraw />,
  [IconName.SETTING]: <Setting />,
  [IconName.BURGER]: <Burger />,
  [IconName.CROSSED_STAR]: <CrossedStar />,
  [IconName.STAR]: <Star />,
  [IconName.ARROW_DROP_DOWN]: <ArrowDropDown />,
  [IconName.SELL]: <Sell />,
  [IconName.FILTER]: <Filter />,
  [IconName.SHARE]: <Share />,
  [IconName.EDIT]: <Edit />,
  [IconName.DELETE]: <Delete />,
  [IconName.ADD_CIRCLE]: <AddCircle />,
  [IconName.AFFILIATE]: <Affiliate />,
  [IconName.GOLD_WALLET]: <GoldWallet />,
  [IconName.ARROW_RIGHT2]: <ArrowRight2 />,
  [IconName.CHEVRON_RIGHT]: <ChevronRight />,
  [IconName.EXPAND_MORE]: <ExpandMore />,
  [IconName.SHOPPING_CART]: <ShoppingCart />,
  [IconName.SELL2]: <Sell2 />,
  [IconName.SHOW]: <Show />,
  [IconName.HIDE]: <Hide />,
  [IconName.CHECK]: <Check />,
  [IconName.ETHERSCAN]: <Etherscan />,
  [IconName.CHECK_SMALL]: <CheckSmall />,
  [IconName.CLOSE_SMALL]: <CloseSmall />,
  [IconName.SEARCH]: <Search />,
  [IconName.PANCAKESWAP]: <Pancakeswap />,
  [IconName.LIST_ALT]: <ListAlt />,
  [IconName.ARROW_DOWN_GREY]: <ArrowDownGrey />,
  [IconName.SNIPE_WHITE]: <SnipeWhite />,
  [IconName.UPDATE_PLAN]: <UpdatePlan />,
  [IconName.CLOSE_MIDDLE]: <CloseMiddle />,
  [IconName.TOLL]: <Toll />,
  [IconName.RADAR]: <Radar />,
  [IconName.CUSTOMIZE]: <Customize />,
  [IconName.PEPE]: <PEPE />,
  [IconName.SMALL_SUCCESS]: <SmallSuccess />,
  [IconName.PENDING]: <Pending />,
  [IconName.WALLET]: <Wallet />,
  [IconName.ARROW_RIGHT_GREY]: <ArrowRightGrey />,
  [IconName.VISIBILITY]: <Visibility />,
  [IconName.PROFIT_SELL]: <ProfitSell />,
  [IconName.DELETE_RED]: <DeleteRed />,
  [IconName.FAVORITE_ETH]: <FavoriteEth />,
  [IconName.TELEGRAM_LINK]: <TelegramLink />,
  [IconName.TELEGRAM_UNLINK]: <TelegramUnlink />,
  [IconName.CLOSE_GREY]: <CloseGrey />,
  [IconName.PENDING_ORANGE]: <PendingOrange />,
  [IconName.PRIVATE_TX]: <PendingBlue />,
  [IconName.MIDDLE_SUCCESS]: <MiddleSuccess />,
  [IconName.COPY_LARGE]: <CopyLarge />,
  [IconName.SHOPPING_CART_LARGE]: <ShoppingCartLarge />,
  [IconName.SNIPE_LARGE]: <SnipeLarge />,
  [IconName.SCRAPE]: <Scrape />,
  [IconName.BASE_CHAIN]: <BaseChain />,
  [IconName.AVAX_CHAIN]: <AvaxChain />,
  [IconName.REFRESH]: <CachedIcon />,
  [IconName.CONNECT_WALLET]: <ConnectWallet />,
  [IconName.ERROR_TX]: <ErrorTx />,
  [IconName.TRIANGLE]: <WarningAmberIcon fontSize="small" />,
  [IconName.ETH_CURRENCY]: <EthCurrency />,
  [IconName.LANGUAGE]: <Language />,
  [IconName.EXCLUDE]: <Exclude />,
  [IconName.SORT_ASC]: <SortAsc />,
  [IconName.SORT_DESC]: <SortDesc />,
  [IconName.TWITTER]: <Twiter />,
  [IconName.CHEVRON_LEFT]: <ChevronLeft />,
  [IconName.METAMASK]: <MetaMask />,
  [IconName.RABBY]: <Rabby />,
  [IconName.WALLETCONNECT]: <WalletConnect />,
  [IconName.FIRE]: <Fire />,
  [IconName.BOLT]: <Bolt />,
  [IconName.MORE]: <More />,
  [IconName.ETHER_LOGO]: <EtherLogo />,
  [IconName.ETHERSCAN_GOLD]: <EtherscanGold />,
  [IconName.SETTING_2]: <SettingsIcon fontSize="small" />,
  [IconName.BUY]: <Buy />,
  [IconName.SWITCH]: <Switch />,
  [IconName.BOTTOM_TABS_BUY]: <BottomTabsBuy />,
  [IconName.BOTTOM_TABS_SELL]: <BottomTabsSell />,
  [IconName.BOTTOM_TABS_PROFIT]: <BottomTabsProfit />,
  [IconName.SIDEBAR_COLLAPSED_BUTTON]: <SidebarCollapsedButton />,
  [IconName.LOCK_WITH_REFLECTION]: <LockWithReflection />,
  [IconName.DEPOSIT]: <Deposit />,
  [IconName.TRANSFER]: <Transfer />,
  [IconName.DOUBLE_ARROW_LEFT]: <DoubleArrowLeft />,
  [IconName.TEMPLATES_GREY]: <TemplatesGrey />,
  [IconName.STAR_GREY_BOLD]: <StarGreyBold />,
  [IconName.HOLDINGS_GREY]: <HoldingsGrey />,
  [IconName.WALLET_GREY]: <WalletGrey />,
  [IconName.TOKEN_BADGE]: <TokenBadge />,
  [IconName.SCHEDULE]: <Schedule />,
  [IconName.COPY_GREY]: <CopyGrey />,
  [IconName.TRADING_STATUS]: <TradingStatus />,
  [IconName.LOCK_GREEN]: <LockGreen />,
  [IconName.SETTINGS_3]: <QuickSettings />,
  [IconName.INFO_ERROR]: <InfoError />,
  [IconName.APPROVE_BIG]: <ApproveBig />,
  [IconName.BUBBLE]: <Bubble />,
  [IconName.ALARM]: <Alarm />,
  [IconName.FEES_COLLECTED]: <FeesCollected />,
  [IconName.GROUP_OF_PEOPLE]: <GroupOfPeople />,
  [IconName.CANDLESTICK]: <Candlestick />,
  [IconName.TROPHY]: <Trophy />,
  [IconName.PRICE_CHECK]: <PriceCheck />,
  [IconName.BSCSCAN]: <Bscscan />,
  [IconName.SOLANA]: <Solana />,
  [IconName.SOLSCAN]: <Solscan />,
  [IconName.PHANTOM]: <Phantom />,
  [IconName.BRIDGE]: <Bridge />,
  [IconName.DROPDOWN_ARROWS]: <DropdownArrows />,
  [IconName.TELEGRAM]: <Telegram />,

  /**
   * Universal icons were added because the old icons were not customizable with hardcoded sizes and colors
   * I added postfix `_` to avoid conflicts
   */
  [IconName.ARROW_DOWN_]: <ArrowDown_ />,
  [IconName.ARTICLE_]: <Article_ />,
  [IconName.CHECK_]: <Check_ />,
  [IconName.CLOSE_]: <Close_ />,
  [IconName.ELECTRIC_BOLT_OUTLINED_]: <ElectricBoltOutlined_ />,
  [IconName.ELECTRIC_BOLT_]: <ElectricBolt_ />,
  [IconName.GEAR_]: <Gear_ />,
  [IconName.PERSON_]: <Person_ />,
  [IconName.PUMP_FUN_]: <PumpFun_ />,
  [IconName.RESTART_]: <Restart_ />,
  [IconName.SETTINGS_]: <Settings_ />,
  [IconName.SOLANA_CIRCLE_]: <SolanaCircle_ />,
  [IconName.SOLANA_]: <Solana_ />,
  [IconName.BASE_NETWORK]: <BaseNetwork />,
  [IconName.ARROW_DROP_DOWN_2]: <ArrowDropDown2 />,
  [IconName.ARROW_DOWN]: <ArrowDown />,
  [IconName.ARROW_UP]: <ArrowUp />,
  [IconName.LANGUAGE_GOLD]: <LanguageGold />,
  [IconName.ARROW_DOWN_MINIMALISTIC]: <ArrowDownMinimalistic />,
  [IconName.ARROW_RIGHT3]: <ArrowRight3 />,
  [IconName.CANCEL]: <Cancel />,
}

export { IconNameToIcon }
