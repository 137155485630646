import { TChainConfig } from '@/libs/types/chain.type'

const formatMarketCap = (value: number): string => {
  if (value >= 1_000_000_000) {
    return (value / 1_000_000_000).toFixed(1) + 'B'
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(1) + 'M'
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(1) + 'K'
  } else {
    return value.toString()
  }
}

const getTriggerMessage = (
  mcType: string,
  mcValue: string,
  currentChain: TChainConfig,
  isSell: boolean,
  available: number,
  limitType?: string,
) => {
  const formattedMC = formatMarketCap(Number(mcValue))
  if (isSell) {
    if (limitType === 'stop_loss') {
      if (mcType === 'mc-by') {
        return `MC ≤ $${formattedMC}, ${currentChain.chainSymbol} ≤ ${available}`
      }
      return `MC decreases ≥ ${formattedMC}%, ${currentChain.chainSymbol} ≤ ${available}`
    }

    if (mcType === 'mc-by') {
      return `MC ≥ $${formattedMC}, ${currentChain.chainSymbol} ≥ ${available}`
    }
  } else {
    return `MC ≤ $${formattedMC}, ${currentChain.chainSymbol} ≤ ${available}`
  }
}

export { getTriggerMessage }
