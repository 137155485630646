import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { TManualBuyForm } from '@/libs/types/manual-buy-form.type'
import { store } from '@/store'

import { convertScientificNotationNumber } from '../convertScientificNotationNumber'
import { stringsToNumbersInObject } from '../stringsToNumbersInObject'

type TWallet = {
  name: string
  public_key: string
}

type TProps = {
  data: TManualBuyForm | any
  wallets: TWallet[]
  pair: string
  expiration?: number
  price_usd: number
  isTemplate?: boolean
  percentMode?: boolean
  degenMode?: boolean
}

const createLimitBuyPayload = ({
  data,
  wallets,
  pair,
  expiration,
  price_usd,
  isTemplate = false,
  percentMode = false,
  degenMode = false,
}: TProps) => {
  {
    const defaultPriorities = getPrioritiesDefault()
    const userData = store.getState().user.userData
    const currentToken = store.getState().chain.currentToken
    const currentChain = store.getState().chain.currentChain
    const currentDex =
      store.getState().chain.adaptedCurrentTokenSimulationWebsocket?.data.liquidity.suggested_dex
    const currentTimeUnix = Math.floor(Date.now() / 1000)
    const expirationInSeconds = expiration ? expiration * 3600 : null

    const chain_currency_amount =
      convertScientificNotationNumber(+data.ordinaryBuy.spend, MAX_TRX_DECIMALS) || 0 // Amount in chain currency

    if (!userData) return

    const shields = stringsToNumbersInObject(data.shields, 0)

    let buy_mode = EBuyMode.CHAIN_CURRENCY
    if (data.advancedBuy.maxTxOrFail) {
      buy_mode = EBuyMode.MAX_TRANSACTION
    } else if (data.advancedBuy.minPercentTokenOrFail) {
      buy_mode = percentMode ? EBuyMode.MIN_PERCENT : EBuyMode.MIN_TOKENS
    }

    const approvePriority = +data.advancedBuy.approvePriority || +defaultPriorities.approvePriority
    const buyPriority = +data.advancedBuy.buyPriority || +defaultPriorities.buyPriority

    if (degenMode) {
      shields.minimum_liquidity = 0
      shields.maximum_liquidity = EBuyMode.MAX_LIQUIDITY
      shields.maximum_market_cap = EBuyMode.MAX_MARKET_CAP
      shields.buy_tax = EBuyMode.MAX_PERCENT
      shields.anti_market_cap = false
    }

    return {
      trigger_type: 'LimitBuy',
      trigger: {
        pair,
        type: 'CurrentBelowPriceNextBlock', // for now only this type is supported
        fixed: {
          price_usd,
        },
        size: {
          chain_currency_amount: +chain_currency_amount,
        },
        execution: {
          retry_duration: 600,
          retry_attempts: 10,
          expiration: expirationInSeconds ? currentTimeUnix + expirationInSeconds : 0,
        },
      },
      buy_template: {
        token: isTemplate || !currentToken ? '' : currentToken.token.address, // Token address a user wants to buy
        network: {
          blockchain: currentChain.indexerChainId, // Current blockchain id
          dex: isTemplate || !currentDex ? null : +currentDex, // Selected dex service
        },
        wallets,
        operation: {
          private_tx: false,
          tx_type: 1,
          bribe_amount: 0,
          contract: {
            separate_txs: true,
          },
          gas: {
            approve: {
              gas: currentChain.features?.noPriorityGas ? approvePriority : 10,
              miner_tip: currentChain.features?.noPriorityGas ? 0 : approvePriority, // 7 is the default value
              auto_calculate: true,
              gas_limit: 1000000,
            },
            buy: {
              gas: currentChain.features?.noPriorityGas ? buyPriority : 10,
              miner_tip: currentChain.features?.noPriorityGas ? 0 : buyPriority, // 7 is the default value
              auto_calculate: true,
              gas_limit: 1000000,
            },
          },
          pair: {
            routing: {
              mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
              path: [], // Not specified, as we only use native tokens so far
            },
            fee: {
              mode: 1, // 1 for the automatic calculation
              path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
            },
          },
          setup: {
            buy_mode,
            buy_amount: isTemplate ? +data.ordinaryBuy.buy_amount : +chain_currency_amount, // Amount of tokens a user wants to exchange
            // Amount of tokens a user wants to buy
            buy_tokens:
              buy_mode === EBuyMode.MIN_TOKENS
                ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '') || 0
                : +data.ordinaryBuy.receive || 0,
            supply_percentage:
              buy_mode === EBuyMode.MIN_PERCENT
                ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '')
                : 0,
            rounds_to_buy: 1, // Default value
            slippage: +data.ordinaryBuy.slippage,
            auto_approve: true, // If true, then no need to call an approve endpoint after the buying
            force_buy: true, // Default value
          },
          shields: {
            anti_fake_liquidity:
              !data.ordinaryBuy.degenChadMode &&
              (!!shields.minimum_liquidity || !!shields.maximum_liquidity),
            anti_tax: !data.ordinaryBuy.degenChadMode && !!(shields.buy_tax || shields.sell_tax),
            anti_honeypot: !data.ordinaryBuy.degenChadMode,
            anti_market_cap: degenMode ? shields.anti_market_cap : !!shields.maximum_market_cap,
            anti_duplicate: shields.anti_duplicate,
            buy_tax: shields.sell_tax && !shields.buy_tax ? 100 : shields.buy_tax,
            sell_tax: shields.buy_tax && !shields.sell_tax ? 100 : shields.sell_tax,
            maximum_liquidity:
              shields.minimum_liquidity && !shields.maximum_liquidity
                ? 1000000000
                : shields.maximum_liquidity,
            minimum_liquidity: shields.minimum_liquidity,
            maximum_market_cap: shields.maximum_market_cap,
            // Default values
            minimum_market_cap: 0,
            anti_honeypot_mode: 0,
            anti_fake_liquidity_mode: 0,
            anti_tax_mode: 0,
            anti_price_impact: false,
            price_impact: 0,
            anti_buy_sell_ratio: false,
            buy_sell_ratio: 0,
            buy_simulation: false,
            buy_simulation_mode: 0,
          },
        },
      },
    }
  }
}
export { createLimitBuyPayload }
