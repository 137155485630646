import { FC, useEffect, useState } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import cn from 'classnames'

import { getListOpenOrders } from '@/api/limit-order'
import { LimitOrdersTable } from '@/components/pending-orders/libs/components/limit-order-table/limit-order-table'
import { TOpenLimitOrder } from '@/libs/types/limit-order.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const LimitOrdersTab: FC<GridProps> = ({ className, ...props }) => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const [rows, setRows] = useState<TOpenLimitOrder[]>([])
  // const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)
  // const [keySort, setKeySorting] = useState('d')

  const fetchOpenOrders = async () => {
    const { data } = await getListOpenOrders(currentToken?.token?.address ?? '')
    return data.data.triggers
  }
  useEffect(() => {
    const orders = fetchOpenOrders()
    orders.then((data) => {
      if (data) {
        const mutableUserHistory = [...data]
        setRows(
          mutableUserHistory.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          ),
        )
      }
    })
  }, [currentToken])

  return (
    <Grid className={cn(styles.tableContainer, className)} {...props}>
      <LimitOrdersTable rows={rows} refetch={fetchOpenOrders} setRows={setRows} />
    </Grid>
  )
}

export { LimitOrdersTab }
