const calculatePriceFromMC = (marketCap: number, totalSupply: number): number => {
  if (!totalSupply) return 0
  return marketCap / totalSupply
}

const calculateMCFromPrice = (price: number, totalSupply: number): number => {
  if (!totalSupply) return 0
  return price * totalSupply
}

export { calculateMCFromPrice, calculatePriceFromMC }
