import { type ForwardedRef, forwardRef } from 'react'

import { type SelectRootSlotProps } from '@mui/base/Select'

import { Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const Root = forwardRef(function Root<
  TValue extends Record<string, unknown>,
  Multiple extends boolean,
>(props: SelectRootSlotProps<TValue, Multiple>, ref: ForwardedRef<HTMLButtonElement>) {
  const { ownerState, ...other } = props

  return (
    <button className={styles.root} {...other} ref={ref}>
      <div className={styles.value}>{other.children}</div>
      <Icon name={IconName.ARROW_DROP_DOWN_2} />
    </button>
  )
})

export { Root }
