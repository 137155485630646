import { type ForwardedRef, forwardRef } from 'react'

import { SelectListboxSlotProps } from '@mui/base/Select'

const Listbox = forwardRef(function Listbox<
  TValue extends Record<string, unknown>,
  Multiple extends boolean,
>(props: SelectListboxSlotProps<TValue, Multiple>, ref: ForwardedRef<HTMLUListElement>) {
  const { ownerState, ...other } = props

  return <ul {...other} ref={ref} />
})

export { Listbox }
