import { FC } from 'react'

import { Option as BaseOption } from '@mui/base/Option'
import { Select } from '@mui/base/Select'

import { Divider, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'

import { Listbox } from '../listbox'
import { Option } from '../option'
import { Root } from '../root'
import styles from '../styles.module.scss'

interface MarketCapDropdownBuyProps {
  value?: string
  onChange?: (value: string) => void
}

const MarketCapDropdownBuy: FC<MarketCapDropdownBuyProps> = ({ value, onChange }) => {
  const cleanValue = value?.replace(/-+$/, '').replace(/^-+/, '') || 'mc-by'

  return (
    <div className={styles.container}>
      <Select
        className={styles.select}
        placeholder=""
        value={cleanValue}
        onChange={(_, newValue) => onChange?.(newValue as string)}
        slots={{
          root: Root,
          listbox: Listbox,
        }}
        slotProps={{
          root: { className: styles.root },
          listbox: { className: styles.listbox },
          popup: { className: styles.popup },
        }}
        renderValue={(option) => {
          const valueString = option?.value?.toString()
          return (
            <>
              {valueString === 'mc-by' ? (
                <>
                  MC <Icon name={IconName.ARROW_DOWN} /> by
                </>
              ) : (
                'MC is'
              )}
            </>
          )
        }}
      >
        {/* <BaseOption
          key={'mc-by'}
          value={'mc-by'}
          slots={{
            root: Option,
          }}
          slotProps={{
            root: {
              className: styles.option,
              value: 'mc-by',
            },
          }}
        >
          MC <Icon name={IconName.ARROW_DOWN} /> by
        </BaseOption> */}
        <BaseOption
          key={'mc-is'}
          value={'mc-is'}
          slots={{
            root: Option,
          }}
          slotProps={{
            root: { className: styles.option, value: 'mc-is' },
          }}
        >
          MC is
        </BaseOption>
      </Select>
      <Divider className={styles.divider} orientation="vertical" />
      <span className={styles.symbol}>≤</span>
    </div>
  )
}

export { MarketCapDropdownBuy }
