import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cls from 'classnames'
import * as yup from 'yup'

import styles from '@/components/dashboard-component-lite/libs/buy-sell-block/styles.module.scss'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import { PriorityCurrency } from '@/components/priority-currency'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Icon,
  Input,
  Typography,
} from '@/libs/common'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { TSelectOption } from '@/libs/types/select-option.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchPriorities } from '@/store/slices/user.slice'

type TProperty = {
  onExpand?: (value: boolean) => void
}

const options: TSelectOption<number>[] = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
]
// type TransactionType = 'market' | 'limit'

const SellTab: FC<TProperty> = ({ onExpand }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const mainUserWallet = useAppSelector((state) => state.user.mainWallet)
  const sellPanel = useAppSelector((state) => state.profitSell.profitSell?.sp)
  const { t } = useTranslation()
  const userData = useAppSelector((state) => state.user.userData)
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const userConfirmationRequired = userData?.sell_confirmation

  const [variant, setVariant] = useState('eth')
  const [available, setAvailable] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [sellPercentage, setSellPercentage] = useState<number>(0)

  const navigate = useCustomNavigate()
  const dispatch = useAppDispatch()

  const mainWallet = useMemo(
    () => sellPanel?.find((panel: any) => panel.wa === mainUserWallet?.address),
    [sellPanel, mainUserWallet],
  )

  const defaultValues = {
    amount: '',
    slippage: currentChain.defaultValues.slippage,
    sellPriority: defaultUserPriorities?.sell_priority ?? '',
    privateTransaction: !currentChain.features?.noPrivateTx,
    bribeAmount: defaultUserPriorities?.bribe_amount ?? '',
    // transactionType: 'market' as TransactionType,
    // marketCap: '',
    // marketCapType: 'mc-by',
    // expiration: '',
    // limitOrderType: 'stop_loss',
  }
  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test((value) => (variant === '%' ? +value <= 100 && +value >= 0 : +value <= available)),
    // transactionType: yup.string().oneOf(['market', 'limit']).required(),
    // marketCap: yup.string().when('transactionType', {
    //   is: 'limit',
    //   then: (schema) => schema.required('Market cap is required for limit orders'),
    //   otherwise: (schema) => schema.optional(),
    // }),
    // expiration: yup.string().when('transactionType', {
    //   is: 'limit',
    //   then: (schema) => schema.required('Expiration is required for limit orders'),
    //   otherwise: (schema) => schema.optional(),
    // }),
    // limitOrderType: yup
    //   .string()
    //   .oneOf(['stop_loss', 'take_profit'])
    //   .when('transactionType', {
    //     is: 'limit',
    //     then: (schema) => schema.required('Order type is required for limit orders'),
    //     otherwise: (schema) => schema.optional(),
    //   }),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })
  // const transactionType = watch('transactionType') as TransactionType
  useEffect(() => {
    if (!userData) return
    const setPriorities = async () => {
      const data = await dispatch(fetchPriorities(null)).unwrap()
      setValue('sellPriority', data.sell_priority)
      setValue('bribeAmount', data.bribe_amount)
    }
    setPriorities()

    setValue('privateTransaction', !currentChain.features?.noPrivateTx)
    setValue('slippage', currentChain.defaultValues.slippage)
  }, [currentChain.id])

  useEffect(() => {
    const coeff =
      currentChain.nativeTokenPriceInUsd && simulation
        ? +simulation.price.usd / currentChain.nativeTokenPriceInUsd
        : 0
    const newValue = convertScientificNotationNumber(
      +(mainWallet?.ba || 0) * coeff,
      MAX_TRX_DECIMALS,
    )
    setAvailable(+newValue)
  }, [mainWallet, currentChain, simulation])

  useEffect(() => {
    setValue('amount', '')
    setSellPercentage(0)
  }, [variant])

  // const tabs = [
  //   {
  //     label: 'Stop Loss',
  //     value: 'stop_loss',
  //     content: null,
  //   },
  //   {
  //     label: 'Take Profit',
  //     value: 'take_profit',
  //     content: null,
  //   },
  // ]

  // const marketCapValue = watch('marketCap')
  // const limitOrderTypeValue = watch('limitOrderType')

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!mainWallet) return
    setIsLoading(true)

    const payload = await createSellPayload({
      wallets: [{ name: mainWallet.wn, public_key: mainWallet.wa }],
      sellPercentage,
      data: {
        receive: data.amount,
        sell: '0',
        sellPriority: data.sellPriority,
        bribeAmount: data.bribeAmount,
        slippage: data.slippage,
        privateTransaction: data.privateTransaction,
      },
      mode: sellPercentage ? 0 : 1,
      dex: mainWallet.sd,
    })

    if (payload) {
      const percentage = +data.amount / available
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: {
            payload,
            data: {
              receive: sellPercentage ? available * (sellPercentage / 100) : data.amount,
              sell: sellPercentage
                ? mainWallet.ba * (sellPercentage / 100)
                : mainWallet.ba * (percentage / 100),
            },
          },
        })
      } else {
        await handleSell(payload)
      }
    }

    if (sellPercentage) setSellPercentage(0)
    setIsLoading(false)
  }

  const renderAdvancedSettings = () => (
    <Accordion
      className={styles.accordion}
      title={
        <div className={styles.accordionTitle}>
          <ButtonIcon icon={IconName.SETTING_2} type="button" />
          <Typography variant="body2">{t('settings.advanced_settings')}</Typography>
        </div>
      }
      withDivider
      mainColorArrow
      onChange={onExpand}
    >
      <Grid container rowGap={2}>
        {!currentChain.features?.noPrivateTx && (
          <Controller
            name="privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label={t('template.anti_mev')}
                tooltipInfo={t('template.anti_mev_tooltip')}
                {...field}
              />
            )}
          />
        )}
        <Controller
          name="slippage"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PercentsInput
              label={t('template.slippage')}
              onOptionSelect={(value) => field.onChange(value)}
              tooltipInfo={t('template.slippage_tooltip')}
              placeholder="X"
              error={!!errors.slippage?.message}
              {...field}
            />
          )}
        />
        {!!currentChain.features?.sellPriority && (
          <Controller
            name="sellPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                className={styles.input}
                label={getChainSensitiveLabel('sellPriority')}
                placeholder={t('template.enter_gwei_amount', {
                  currency: currentChain.priorityCurrency.toLowerCase(),
                })}
                isNumeric
                tooltipInfo={`${t('template.enter_gwei_amount_tooltip')} ${currentChain.chainSymbol}.`}
                endAdornment={<PriorityCurrency />}
                error={!!errors.sellPriority?.message}
                {...field}
              />
            )}
          />
        )}
        {!!currentChain.features?.bribeAmount && (
          <Controller
            name="bribeAmount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                {...field}
                className={styles.input}
                label={getChainSensitiveLabel('bribeAmount')}
                tooltipInfo={t('token_info.bribe_amount_tooltip')}
                placeholder={t('token_info.bribe_amount_placeholder')}
                isNumeric
                endAdornment={<PriorityCurrency />}
                error={!!errors.bribeAmount?.message}
                onChange={(e: any) => {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  field.onChange(newValue)
                }}
              />
            )}
          />
        )}
      </Grid>
    </Accordion>
  )

  const renderAmountControls = () => (
    <>
      <div className={styles.worthBlock}>
        <Typography variant="body2">{t('sell_tab.worth_in_usd')}: </Typography>
        {/* <Icon name={IconName.ETH_CURRENCY} /> */}
        <Typography variant="body2" textColor="light-grey">
          ${mainWallet ? formatNumber(mainWallet.w.u).formatted : 0}
        </Typography>
      </div>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <ButtonGroupRadio
            solid
            exclusive
            className={styles.radioGroup}
            groupClassName={styles.radioGroupContent}
            value={value}
            onChange={(_, value) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              if (variant === '%') {
                setSellPercentage(+value)
                onChange(value)
                return
              }
              const newValue = convertScientificNotationNumber(
                available * (+value / 100),
                MAX_TRX_DECIMALS,
              )
              setSellPercentage(+value)
              onChange(newValue)
            }}
            {...field}
          >
            {options?.map((el, index) => (
              <ButtonGroupRadioButton
                value={el.value}
                name="wallet"
                className={styles.customButton}
                key={index}
              >
                {ChainNameToNativeTokenIcon[currentChain.chainName]}
                <Typography variant="body2" textColor="light-grey">
                  {el.label}
                </Typography>
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <Input
            label={t('token_info.amount')}
            placeholder={t('amount_values.amount_to_sell')}
            isNumeric
            className={styles.input}
            onChange={(e) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              if (variant === '%') {
                setSellPercentage(+e.target.value)
                onChange(e.target.value)
                return
              }
              if (!e.target.value) {
                onChange(e.target.value)
                return
              } else {
                const newValue = convertScientificNotationNumber(
                  e.target.value,
                  MAX_TRX_DECIMALS,
                  true,
                )
                onChange(newValue)
              }

              if (sellPercentage) setSellPercentage(0)
            }}
            error={!!errors.amount?.message}
            {...field}
            endAdornment={
              <ButtonGroupRadio
                exclusive
                className={styles.switch}
                value={variant}
                onChange={(_, newValue) => setVariant(newValue)}
              >
                <ButtonGroupRadioButton
                  value="eth"
                  className={cls(styles.switchButton, variant === 'eth' && styles.active)}
                >
                  <Typography variant="body2" textColor="color-grey-3">
                    {currentChain.chainSymbol}
                  </Typography>
                </ButtonGroupRadioButton>
                <ButtonGroupRadioButton
                  value="%"
                  className={cls(styles.switchButton, variant === '%' && styles.active)}
                >
                  <Typography variant="body2" textColor="color-grey-3">
                    %
                  </Typography>
                </ButtonGroupRadioButton>
              </ButtonGroupRadio>
            }
          />
        )}
      />
      <div className={styles.available}>
        <Typography variant="body2" className={styles.param}>
          {t('available')}:
        </Typography>
        <Typography variant="body2" className={styles.value}>
          {(+available || 0).toLocaleString('en-US', {
            maximumFractionDigits: 5,
          })}{' '}
          {currentChain.chainSymbol}
        </Typography>
      </div>
    </>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Controller
        name="transactionType"
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} className={cls(styles.txType, styles.sellTab)}>
            <FormControlLabel
              value="market"
              control={<Radio label="Sell now" />}
              label=""
              className={styles.radioWrapper}
            />
            <FormControlLabel
              value="limit"
              control={<Radio label="Limit Sell" />}
              label=""
              className={styles.radioWrapper}
            />
          </RadioGroup>
        )}
      /> */}
      {renderAdvancedSettings()}

      {/* {transactionType === 'limit' ? (
        <div className={styles.container}>
          <Controller
            name="limitOrderType"
            control={control}
            render={({ field }) => (
              <Tabs
                tabs={tabs}
                value={tabs.findIndex((tab) => tab.value === field.value)}
                onChange={(_, value) => field.onChange(tabs[value].value)}
                className={styles.limitSellTabs}
                name={TabName.LIMIT_SELL}
              />
            )}
          />
          <Controller
            name="marketCap"
            control={control}
            render={({ field }) => (
              <Input
                label=""
                placeholder=""
                isNumeric
                className={cls(styles.input, styles.limitSellInput)}
                maxLength={MAX_AMOUNT_LENGTH}
                isHideInputCaption
                startAdornment={
                  <Controller
                    name="marketCapType"
                    control={control}
                    render={({ field: typeField }) => (
                      <MarketCapDropdownSell orderType={limitOrderTypeValue} {...typeField} />
                    )}
                  />
                }
                endAdornment={<EndAdornment label={'%'} />}
                onChange={(e) => {
                  if (!currentToken || !currentChain.nativeTokenPriceInUsd) return

                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  field.onChange(newValue)
                }}
                value={field.value}
                error={!!errors.marketCap?.message}
              />
            )}
          />
          <div className={styles.triggerBlock}>
            <TooltipIcon info="" />
            <div className={styles.trigger}>
              <div className={styles.triggerHeader}>
                <Typography variant="body2" className={styles.param}>
                  Triggers when:
                </Typography>
              </div>
              <Typography variant="body2" className={styles.value}>
                {getTriggerMessage(
                  marketCapValue?.split('-')[0],
                  marketCapValue?.split('-')[1],
                  Number(simulation?.market_cap) || 0,
                  currentChain,
                  true,
                  available,
                  limitOrderTypeValue,
                )}
              </Typography>
            </div>
          </div>
          <Controller
            name="amount"
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => {
              return (
                <ButtonGroupRadio
                  solid
                  exclusive
                  className={cls(styles.radioGroup, styles.limitRadioGroup)}
                  groupClassName={styles.radioGroupContent}
                  value={value}
                  onChange={(_, value) => {
                    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                    if (variant === '%') {
                      setSellPercentage(+value)
                      onChange(value)
                      return
                    }
                    const newValue = convertScientificNotationNumber(
                      available * (+value / 100),
                      MAX_TRX_DECIMALS,
                    )
                    setSellPercentage(+value)
                    onChange(newValue)
                  }}
                  {...field}
                >
                  {options?.map((el, index) => (
                    <ButtonGroupRadioButton
                      value={el.value}
                      name="wallet"
                      className={styles.customButton}
                      key={index}
                    >
                      {ChainNameToNativeTokenIcon[currentChain.chainName]}
                      <Typography variant="body2" textColor="light-grey">
                        {el.label}
                      </Typography>
                    </ButtonGroupRadioButton>
                  ))}
                </ButtonGroupRadio>
              )
            }}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => {
              return (
                <Input
                  label="Amount"
                  placeholder="Enter amount to sell"
                  isNumeric
                  className={styles.input}
                  onChange={(e) => {
                    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                    if (variant === '%') {
                      setSellPercentage(+e.target.value)
                      onChange(e.target.value)
                      return
                    }
                    if (!e.target.value) {
                      onChange(e.target.value)
                      return
                    } else {
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }

                    if (sellPercentage) setSellPercentage(0)
                  }}
                  error={!!errors.amount?.message}
                  {...field}
                  endAdornment={
                    <ButtonGroupRadio
                      exclusive
                      className={styles.switch}
                      value={variant}
                      onChange={(_, newValue) => setVariant(newValue)}
                    >
                      <ButtonGroupRadioButton
                        value="eth"
                        className={cls(styles.switchButton, variant === 'eth' && styles.active)}
                      >
                        <Typography variant="body2" textColor="color-grey-3">
                          {currentChain.chainSymbol}
                        </Typography>
                      </ButtonGroupRadioButton>
                      <ButtonGroupRadioButton
                        value="%"
                        className={cls(styles.switchButton, variant === '%' && styles.active)}
                      >
                        <Typography variant="body2" textColor="color-grey-3">
                          %
                        </Typography>
                      </ButtonGroupRadioButton>
                    </ButtonGroupRadio>
                  }
                />
              )
            }}
          />

          <div className={styles.available}>
            <Typography variant="body2" className={styles.param}>
              Available:
            </Typography>
            <Typography variant="body2" className={styles.value}>
              {(+available || 0).toLocaleString('en-US', {
                maximumFractionDigits: 5,
              })}{' '}
              {currentChain.chainSymbol}
            </Typography>
          </div>
          <Controller
            name="expiration"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => {
              return (
                <Input
                  label="Expires in hours"
                  placeholder=""
                  isNumeric
                  className={styles.input}
                  maxLength={MAX_AMOUNT_LENGTH}
                  isHideInputCaption
                  endAdornment={
                    <EndAdornment
                      label={'Hours'}
                      isLowercase={true}
                      // icon={(IconName as any)[currentChain.iconName]}
                    />
                  }
                  onChange={(e) => {
                    if (!currentToken || !currentChain.nativeTokenPriceInUsd) return

                    if (!e.target.value) {
                      onChange(e.target.value)
                      return
                    } else {
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }
                  }}
                  error={!!errors.amount?.message}
                  {...field}
                />
              )
            }}
          />
        </div>
      ) : (
        renderAmountControls()
      )} */}
      {renderAmountControls()}

      {!currentChain.features?.noPriorityGas && (
        <Link
          to={`${AppRoute.DASHBOARD}/${AppMode.LITE}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
          className={styles.link}
        >
          <Typography variant="body2">
            {getChainSensitiveLabel('setupDefaultPriorities')} <Icon name={IconName.SETTING_2} />
          </Typography>
        </Link>
      )}
      <div className={styles.divider}>
        <Typography variant="body2" className={styles.text}>
          {t('buy_tab.or')}
        </Typography>
      </div>
      <Button type="submit" isLoading={isLoading} checkOnAccountLock>
        {t('sell')}
      </Button>
    </form>
  )
}

export { SellTab }
